
import React from 'react';
import { number, NumberInput, Pagination, TextField, ReferenceManyField, Datagrid, FormTab, TabbedForm, ReferenceInput, TextInput, Edit, SimpleForm, SelectInput, required, useTranslate } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import { EditButton, ListButton, Toolbar, SaveButton, DeleteButton } from 'react-admin';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { DateInput } from 'react-admin-date-inputs2';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import {orderTypes, OrderType, ACTIVITY_TYPE_TAXI, ACTIVITY_TYPE_VTC, ACTIVITY_TYPE_TPMR} from '../../util/constant'
import {activityTypes} from '../../util/variant'
import { ValidateField } from '../../control/field';
// import {AddPriceTimeButton} from '../../button/field';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    
});
const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbarNoStyle = ({permissions, ...props}) => (
    <Toolbar {...props}>
        <SaveButton />
        {permissions === 'Admin' &&
        <DeleteButton label="ra.action.delete" redirect='list' {...props}/>}
    </Toolbar>
);

const CustomToolbar = withStyles(toolbarStyles)(CustomToolbarNoStyle);

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const EditActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath}/>
    </CardActions>
);

const Title = ({ record }) => {
    // var string = "";
    // if(record){
    //     const vehicleTypeName = record ? record.vehicle_type_name : '';
    //     const orderType = record ? record.order_type : 0;
    //     if(orderType == OrderType.IMMEDIATE)
    //         string = vehicleTypeName + " - Immédiate";
    //     else
    //         string = vehicleTypeName + " - Réservation à l'avance";
    // }
    const translate = useTranslate();
    // return <span>{translate('resources.price_distance.show')} pour: {record ? `${string}` : ''}</span>;
    return <span>{translate('resources.price_subscription.edit')}</span>;
    
};

const validatePricePositive = (value, allValues) => {
    if (value <= 0) {
        return 'Doit être plus grand que 0';
    }
    return [];
};

const validatePriceNotNegative = (value, allValues) => {
    if (value < 0) {
        return 'Doit ne pas être moins que 0';
    }
    return [];
};

const validatePrice = [required(), number(), validatePricePositive];
const validatePriceAdditional = [required(), number(), validatePriceNotNegative];

// var activityTypes = [];
// activityTypes.push({id: ACTIVITY_TYPE_TAXI, name: "Taxi"});
// activityTypes.push({id: ACTIVITY_TYPE_VTC, name: "VTC"});
// activityTypes.push({id: ACTIVITY_TYPE_TPMR, name: "TPMR"});

const EditPrice = ({permissions, ...props })  => {
    const classes = useStyles();
    return (
    <Edit title={<Title />} {...props} actions={<EditActions/>}>
         {/* <TabbedForm redirect="list" 
                toolbar={<CustomToolbar/>}>
                    <FormTab label="Configuration de base"> */}
                    <SimpleForm redirect="list" toolbar={<CustomToolbar/>}>
                        {/* <ReferenceInput source="vehicle_type_id" perPage={99999} className={classes.inlineBlock}
                        reference="vehicle_type" label="Type de véhicule">
                            <SelectInput optionText="name"/>
                        </ReferenceInput> */}
                        <TextInput source="from_count" label="Du nombre de chauffeurs" validate={validatePriceAdditional} className={classes.inlineBlock}/>
            <TextInput source="to_count" label="Au nombre de chauffeurs" validate={validatePriceAdditional} className={classes.inlineBlock}/>
            <TextInput source="price" label="Prix(€)/chauffeur" validate={validatePriceAdditional} className={classes.inlineBlock}/>
                    </SimpleForm>
                {/* </FormTab>
                <FormTab label="Majorité/réduction d'horaire">
                    <ReferenceManyField pagination={<Pagination />} reference="price_time" target="price_id" addLabel={false}>
                        <Datagrid>
                            <ValidateField source="every_day" label="Tous les jours"/>
                            <TextField source="day" label="Dates"/>
                            <TextField source="markup" label="Majorité/Réduction"/>
                            <TextField source="from_time" label="Heure de début"/>
                            <TextField source="to_time" label="Heure de fin"/>
                            <EditButton basePath="/price_time"/>
                        </Datagrid>
                    </ReferenceManyField>
                    

                </FormTab>
                <FormTab label="Forfait">
                    <ReferenceManyField pagination={<Pagination />} reference="price_route" target="price_id" addLabel={false}>
                        <Datagrid expandHeader={<div/>}>
                            <TextField source="from_id" label="From"/>
                            <TextField source="to_id" label="To"/>
                            <TextField source="per_package" label="Prix(€)"/>
                            
                            
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab> */}
        {/* </TabbedForm> */}
    </Edit>
)};

export default EditPrice;