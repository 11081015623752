
import React, {Link, Component} from 'react';
import { Pagination, DateField, ReferenceManyField, Datagrid, SimpleShowLayout,TextField, Show,useTranslate, TabbedShowLayout, Tab, ImageField } from 'react-admin';
import { EditButton, ListButton } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import FileIcon from '@material-ui/icons/Attachment';
import DeleteButton from '../../control/button/delete-button';
import FileButton from '../../control/button/validate-button';
import {theme} from '../../util/variant'

// const Title = ({ record }) => {
//     return <span>Mon profil</span>;
// };
import ImageViewer from '../../control/viewer/image-viewer';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {DataStatusField, DocStatusField, OrderStatusField, FormattedDateField, AvatarField, GenderField, ValidateField} from '../../control/field/index'
import {LockVehicleButton, UnlockVehicleButton, VehicleDateEditButton, DocDateEditButton, ValidateButton, LockButton, UnlockButton} from '../../control/button/index'

import {FALSE, TRUE, USER_TYPE_COMPANY_AND_DRIVER, VehicleStatus} from '../../util/constant'
import {BACKEND_FILE_URL} from '../../util/variant'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorLabel: {
        paddingLeft: 50,
        color: '#F4004E',
    },

    colorPrimary: {
        color:'#F4004E',
    },
    
    noColorLabel: {
        paddingLeft: 50,  
    },

    colorValue: {
        color: '#F4004E',
        paddingLeft:5
    },

    supplementText: {
        color: '#F4004E',
    },

    noHeader:{
        display:'none',
        // height:'1px'
    },

    image:{
        width:'200px',
        height:'200px',
    }
    
});

const styles = {
    flex: { display: 'flex'},
    images:{display: 'flex', paddingLeft:'1em', marginLeft:"3em" },
    flexColumn: { display: 'flex', flexDirection: 'column', },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    map:{ flex: 1, marginTop: '1em', marginBottom: '1em' },
    button:{ flex: 1, marginTop: '1em'},
};



const cardActionStyle = {
    // zIndex: 2,
    // display: 'inline-block',
    // float: 'right',
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const DocNameField = ({record, label}) => {
    // const classes = useStyles();
    console.log('Doc record: ' + JSON.stringify(record));
    // if(typeof(record) !== 'undefined' && typeof(record.name) !== 'undefined'){
    //     const nameObj = JSON.parse(record.name);
    //     // const empty = record.files.length > 0? '(' + record.files.length + ')' : ''; 
    //     // return <div><span>{nameObj.fr}</span><span className={classes.supplementText}>{empty}</span></div>
    //     return <div><span>{nameObj.fr}</span></div>;
    // }
    if(typeof(record) !== 'undefined' && typeof(record.doc_name) !== 'undefined'){
        // const nameObj = JSON.parse(record.name);
        // const empty = record.files.length > 0? '(' + record.files.length + ')' : ''; 
        // return <div><span>{nameObj.fr}</span><span className={classes.supplementText}>{empty}</span></div>
        return <div><span>{record.doc_name}</span></div>;
    }
    return <div/>;
    
}
DocNameField.defaultProps = {
    addLabel:true
}

const FileCountField = ({record, label}) => {
    const classes = useStyles();
    console.log('Doc record: ' + JSON.stringify(record));
    if(typeof(record) !== 'undefined' && typeof(record.name) !== 'undefined'){
        return <div><span className={classes.colorValue}>{record.files.length}</span></div>
    }
    
    return <div><span className={classes.colorValue}>0</span></div>
    
}

FileCountField.defaultProps = {
    addLabel:true
}


const ShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath}/>
    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.driver.show')}: {record ? `${record.email}` : ''}</span>;
};


const UserTypeField = ({ record = {}, label}) => {
    return <div>
        <Typography variant="body2">
            {record.user_type == USER_TYPE_COMPANY_AND_DRIVER? 'Autre' : 'Auto-entrepreneur'}
        </Typography>
        {/* <span class>{value}</span> */}
    </div>;
}

UserTypeField.defaultProps = {
    addLabel: true,
};

const ClientDataField = ({ record = {}, source, label}) => {
    const client = record.client;
    if(typeof(client) !== 'undefined' && client !== null && client !== ''){
        const clientObj = JSON.parse(client); 
        return <Typography variant="body2">{clientObj[source]}</Typography>
    }
    
    return <span></span>;
}

ClientDataField.defaultProps = {
    addLabel: true,
};


const VehicleDateField = ({ record = {}, label}) => {
    const classes = useStyles();
    return record.circulation_date !== "" ? <div><br/>
            <Typography variant="body2" className={classes.noColorLabel}>
            Date de la 1e mise en circulation: {record.date !== "" ? <DateField className={classes.colorValue} source="circulation_date" record={record} label=""  locales="fr-FR"/> : '--'}
            
        </Typography><br/>
    </div> : '';
}

const ValidityDateField = ({ record = {}, label}) => {
    const classes = useStyles();
    return record.date_id !== "" ? <div><br/>
            <Typography variant="body2" className={classes.noColorLabel}>
            Date de validité: {record.date !== "" ? <DateField className={classes.colorValue} source="date" record={record} label=""  locales="fr-FR"/> : '--'}
            
        </Typography><br/>
    </div> : '';
}


const DocContentPanel = ({ id, record, resource }) => {
    const classes = useStyles();
    console.log("File record " + JSON.stringify(record));
    var files = record.files;
    files.forEach(function(file, index){
        if(!file.file_url.startsWith("http://") && !file.file_url.startsWith("https://")){
            file.file_url = BACKEND_FILE_URL + file.file_url;
            files[index] = file;
        }
    }
    , files);

    if(files.length === 0)
        return <div>
        <ValidityDateField record={record}/>
        <Typography variant="body2" className={classes.colorLabel}>Aucun fichier</Typography>
        </div>;
    
    return(
        <div style={styles.flexColumn}>
            <Typography variant="body2" className={classes.noColorLabel}>Fichier(s):</Typography>
            <br/>
            <div style={styles.images}>        
                {files.map(file => {
                    return <ImageViewer files={files} fileUrl={file.file_url} title="" width='300' height='300' className={classes.noColorLabel}/>
                })}
            </div>
            <ValidityDateField record={record}/>
            
        </div>
    )
};

const VehicleContentPanel = ({ id, record, resource }) => {
    const classes = useStyles();
    console.log("File record " + JSON.stringify(record));
    var insuranceCertUrl = record.insurance_cert_url;
    if(insuranceCertUrl !== '' && !insuranceCertUrl.startsWith("http://") && !insuranceCertUrl.startsWith("https://"))
        insuranceCertUrl = BACKEND_FILE_URL + insuranceCertUrl;

    var grayCardUrl = record.gray_card_url;
    if(grayCardUrl !== '' && !grayCardUrl.startsWith("http://") && !grayCardUrl.startsWith("https://"))
        grayCardUrl = BACKEND_FILE_URL + grayCardUrl;
    
    var frontImageUrl = record.front_image_url;
    if(frontImageUrl !== '' && !frontImageUrl.startsWith("http://") && !frontImageUrl.startsWith("https://"))
        frontImageUrl = BACKEND_FILE_URL + frontImageUrl;

    var rearImageUrl = record.rear_image_url;
    if(rearImageUrl !== '' && !rearImageUrl.startsWith("http://") && !rearImageUrl.startsWith("https://"))
        rearImageUrl = BACKEND_FILE_URL + rearImageUrl;

    var files = [];
    files[0] = {file_url: frontImageUrl, name: 'Photo extérieure du Véhicule (face avant et plaque visible)'};
    files[1] = {file_url: rearImageUrl, name: 'Photo intérieur du véhicule'};
    files[2] = {file_url: insuranceCertUrl, name: 'Carte verte'};
    files[3] = {file_url: grayCardUrl, name: 'Carte grise'};
    return(
       
            <div style={styles.flexColumn}>
                <div style={styles.images}>        
                    {files.map(file => {
                        return <ImageViewer files={files} fileUrl={file.file_url} title={file.name} width='300' height='300'/>
                    })}
                </div>
                <VehicleDateField record={record}/>
                
            </div>
    );
};

// const userId = localStorage.getItem('user_id');
// const role = localStorage.getItem('role');

const ShowDriverDeleted = ({staticContext, ...props}) => {
    const translate = useTranslate();
    const classes = useStyles();
    return(
    <Show component="div" title={<Title />} actions={<ShowActions/>} {...props}>
            <TabbedShowLayout redirect="list">
            <Tab label="Profil">
                <AvatarField source="photo_url" label="" size='80'/>
                {/* <TextField source="user_number" label="ID"/> */}
                <TextField source="id" label="ID"/>
                <GenderField label={translate('civility')}/>
                <div/>
                <TextField source="last_name" label="Nom" className={classes.inlineBlock}/>
                <TextField source="first_name" label="Prénom " className={classes.inlineBlock}/>
                <div/>
                <div/>
                <TextField source="mobile" label = "Mobile" className={classes.inlineBlock}/>
                <TextField source="email" label = "Email" className={classes.inlineBlock}/>
                <br/>
                <DateField source="create_time" label="Date d'inscription"  locales="fr-FR" className={classes.inlineBlock}/>
                <DateField source="delete_time" label="Date de suppression"  locales="fr-FR" className={classes.inlineBlock}/>
                <br/>
                <Divider/>
                <br/>
                {/* <Typography variant="body2">État</Typography> */}
                <ValidateField source="activated" label='Activé' className={classes.inlineBlock}/>
                <ValidateField source="validated" label='Validé' className={classes.inlineBlock}/>
                <ValidateField source="locked" label='Verrouillé' className={classes.inlineBlock}/>
                <ValidateField source="deleted" label='Supprimé' className={classes.inlineBlock}/>
            </Tab>

            <Tab label="Informations de la société">
                <UserTypeField label="Type de société"/>
                <TextField source="company_name" label = "Nom"/>
                <TextField source="company_address" label = "Adresse"/>
                <div/>
                <TextField source="postal_code" label="Code postal" className={classes.inlineBlock}/>
                <TextField source="city" label="Ville" className={classes.inlineBlock}/>
                
                
            </Tab>
        
            <Tab label="Documents d'activité">
                <ReferenceManyField reference="doc" target="user_id" addLabel={false}>
                    <Datagrid expand={<DocContentPanel/>} expandHeader={<div/>}>
                        <DocNameField label="Nom du document"/>
                        
                    </Datagrid>
                </ReferenceManyField>
                
            </Tab>
            <Tab label="Véhicules">
                <ReferenceManyField reference="vehicle" target="user_id" addLabel={false}>
                        <Datagrid expand={<VehicleContentPanel/>}>
                        <TextField source="plate_number" label="Plaque" className={classes.colorValue} />
                        <TextField source="brand_name" label="Marque"/>
                        <TextField source="model_name" label="Modèle"/>
                        <TextField source="vehicle_type_name" label="Type"/>
                        <TextField source="color" label="Couleur"/>
                        {/* <FormattedDateField source="circulation_date" label="Date de la 1e mise en circulation"  locales="fr-FR"/> */}
                        {/* <FormattedDateField source="control_date" label="Date du contrôle technique"  locales="fr-FR"/> */}
                        
                        <ValidateField source="is_default" label="Par défaut"/>
                        <DataStatusField source="status" label="Etat"/>
                        <ValidateField source="is_locked" label="Vérrouillé"/>
                        {/* <VehicleDateEditButton/> */}
                    </Datagrid>
                </ReferenceManyField>
                
            </Tab>
            <Tab label="Commandes">
            <ReferenceManyField pagination={<Pagination />} reference="order_driver" target="driver_id" addLabel={false}>
                        <Datagrid>
                        <TextField source="create_time" label="Date"/>
                        <TextField source="order_number" label="Nº de commande"/>
                        <ClientDataField source="last_name" label="Nom du client"/>
                        <ClientDataField source="first_name" label="Prénom du client"/>
                        <TextField source="from_address" label="Adresse de départ"/>
                        <TextField source="to_address" label="Adresse d'arrivée"/>
                        <TextField source="total" label="Prix(€)"/>
                        <OrderStatusField source="status" label = "État"/>
                        <TextField source="rating_on_driver" label="Note attribué par le client"/>
                        </Datagrid>
                    </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>

       
    </Show>);
};


export default ShowDriverDeleted;