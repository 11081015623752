
import React, {Link} from 'react';
import { ReferenceField, SimpleShowLayout,TextField, Show, useTranslate , ReferenceArrayField, SingleFieldList, ChipField} from 'react-admin';
import { EditButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
// const Title = ({ record }) => {
//     return <span>Mon profil</span>;
// };

import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {locationDict} from '../../util/variant'
import {OrderPriceChangeButton, OrderValidateButton} from '../../control/button/index' 
import {OrderRunningStatusField, OrderStatusField, FormattedDateField, OrderTypeField, ValidateField,  
    FullNameClientField, FullNameDriverField, OrderDiscountStatusField} from '../../control/field/index'

// const cardActionStyle = {
//     zIndex: 2,
//     display: 'inline-block',
//     float: 'right',
// };
const cardActionStyle = {
    // zIndex: 2,
    // display: 'inline-block',
    // float: 'right',
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};


const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorPrimary: {
        color:'#F4004E',
    },
});



// const changePassword = () => {
//     // window.location.href = '/#/change-password';
// }

const ShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        {/* <EditButton basePath='/my-profile' record={data} /> */}
        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={changePassword}>CHANGER LE MOT DE PASSE</Button> */}
        {/* <Link to="/change-password">Changer le mot de passe</Link> */}
        {typeof data != 'undefined' &&  typeof data.manual_dispatching != 'undefined' && data.manual_dispatching == 0 &&
            <OrderPriceChangeButton basePath={basePath} record={data} resource={resource}/>
        }
        {/* <OrderPriceChangeButton basePath={basePath} record={data}/> */}
        {/* {typeof data != 'undefined' &&  typeof data.manual_dispatching != 'undefined' && data.manual_dispatching == 0 &&
            <OrderValidateButton basePath={basePath} record={data} resource={resource}/>
        } */}
    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.order_running.show')}: #{record ? `${record.order_number}` : ''}</span>;
    
};



// const userId = localStorage.getItem('user_id');
// const role = localStorage.getItem('role');

const ShowOrderRunning = ({staticContext, ...props}) => {
    const classes = useStyles();
    return(
    // <Show title={<Title />} {...props} actions={<ShowActions />}>
    <Show component="div" title={<Title />} actions={<ShowActions/>} {...props}>
        <SimpleShowLayout>
        <div/>
                {/* <ValidateField source="order_sub_type" label="Course à vide ?"/> */}
                {/* <OrderRunningStatusField source="status" label = "État" className={classes.colorPrimary}/> */}
                <OrderDiscountStatusField source="status" label = "État"/>
                <TextField source="order_number" label="Nº de commande" className={classes.inlineBlock} />
                <OrderTypeField source="order_type" label= "Type de commande" className={classes.inlineBlock}/>
                <div/>
                <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" link="" className={classes.inlineBlock}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Gamme de véhicule" source="vehicle_class_id" reference="vehicle_class" link="" className={classes.inlineBlock}>
                    <TextField source="name" />
                </ReferenceField>
                {/* <OrderActivityTypeField source="order_type" label= "Type d'activité" className={classes.inlineBlock}/> */}
                <div/>
                <FormattedDateField source="create_time" format="format_date_time" label="Date/heure"/>
                

                {/* <FullNameClientField label="Client" className={classes.inlineBlock}/> */}
                <FullNameDriverField label="Chauffeur" className={classes.inlineBlock}/>
                {/* <AnimalField/> */}

                {/* <TextField source="c_last_name" label="Nom du client" className={classes.inlineBlock}/>
                <TextField source="c_first_name" label="Prénom du client" className={classes.inlineBlock}/>
                <div/>
                <TextField source="d_last_name" label="Nom du chauffeur" className={classes.inlineBlock}/>
                <TextField source="d_first_name" label="Prénom du chauffeur" className={classes.inlineBlock}/> */}

                <div/>
                <TextField source="from_address" label = "Adresse de départ"/>
                <TextField source="to_address" label = "Adresse d'arrivée"/>
                <FormattedDateField source="pick_time" format="format_date_time" label="Prise en charge demandée"/>
                
                {/* <TextField source="passenger_count" label = "Nombre de passagers" className={classes.inlineBlock}/> */}
                
                {/* <ReferenceArrayField label="Type de passagers" reference="data_type" source="passenger_type" className={classes.inlineBlock}>
                                        <SingleFieldList>
                                            <ChipField source="name" />
                                        </SingleFieldList>
                                    </ReferenceArrayField>
                                    <div/>
                                    <TextField source="luggage_count" label = "Nombre de bagages" className={classes.inlineBlock}/>
                <ReferenceArrayField label="Type de bagages" reference="data_type" source="luggage_type" className={classes.inlineBlock}>
                                        <SingleFieldList>
                                            <ChipField source="name" />
                                        </SingleFieldList>
                                    </ReferenceArrayField>
                                    <div/>
                <ReferenceArrayField label="Options" reference="option" source="options">
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <TextField source="extra.note" label = "Commentaire au chauffeur"/> */}
                <br/>
                <Divider/>
                {/* <br/> */}
                {/* <TextField source="original_total" label="Montant sans frais d'attente(€)"/> */}
                {/* <TextField source="wait_amount" label="Frais d'attente(€)" className={classes.inlineBlock}/> */}
                <Typography style={{ fontWeight: 600, fontSize:15 }}>Montants</Typography>
                
                {/* <TextField source="commission_amount" label="Commission(€)" className={classes.inlineBlock}/>
                <TextField source="driver_amount" label="Commission chauffeur(€)" className={classes.inlineBlock}/> */}
                <div/>
                <TextField source="original_total" label="Prix calculé(€)" className={classes.inlineBlock}/>
                <div/>
                <TextField source="total" label="Prix saisi par l'admin(€)" className={classes.inlineBlock}/>
                <br/>
                {/* <Divider/>
                <ValidateField source="manual_dispatching" label="Validé ?"/> */}
                {/* <TextField source="tips_amount" label="Pourboire au chauffeur(€)"/> */}
                {/* <TextField source="pt_name" label="Mode de paiement"/> */}

                {/* <br/>
                <Divider/> */}
                
                {/* <Typography variant="body2" color="primary">Déroulement</Typography> */}
                {/* <Typography style={{ fontWeight: 600, fontSize:15 }}>Déroulement</Typography> */}
                {/* <div/>
                <TextField source="wait_min" label="Temps d'attente(mn)"/> */}
                {/* <FormattedDateField source="start_time" format="format_date_time" label="Heure de début"/> */}
                <div/>
                {/* <FormattedDateField source="at_pick_time" format="format_date_time" label="Chauffeur sur place" className={classes.inlineBlock}/> */}
                {/* <FormattedDateField source="start_time" format="format_date_time" label="Début" className={classes.inlineBlock}/> */}
                {/* <FormattedDateField source="end_time" format="format_date_time" label="Heure d'arrivée" className={classes.inlineBlock}/> */}
                <div/>
                {/* <FormattedDateField source="end_time" format="format_date_time" label="Heure d'arrivée"/>
                <FormattedDateField source="cancel_time" format="format_date_time" label="Heure d'annulation"/> */}
            {/* <OrderStatusField source="status" label = "État" className={classes.colorPrimary}/>
            <FormattedDateField source="create_time" format="format_date_time" label="Date/heure"/>
            
            <div/>
            <TextField source="order_number" label="Nº de commande" className={classes.inlineBlock}/>
            <OrderTypeField source="order_type" label= "Type de commande" className={classes.inlineBlock}/>
            <div/>
            <TextField source="c_last_name" label="Nom du client" className={classes.inlineBlock}/>
            <TextField source="c_first_name" label="Prénom du client" className={classes.inlineBlock}/>
            <div/>
            <TextField source="from_address" label = "Adresse de départ"/>
            <TextField source="to_address" label = "Adresse d'arrivée"/>
            <FormattedDateField source="pick_time" format="format_date_time" label="Prise en charge demandé"/>
            <div/>
            <TextField source="d_last_name" label="Nom du chauffeur" className={classes.inlineBlock}/>
            <TextField source="d_first_name" label="Prénom du chauffeur" className={classes.inlineBlock}/>
            <div/>
          
            
            <TextField source="total" label="Prix(€)"/>
            <TextField source="commission_amount" label="Commission(€)"/>
            <TextField source="driver_amount" label="Commission chauffeur(€)"/>
            
            
            
            <div/>
            <Divider/>
            <br/>
            <Typography variant="body2" color="primary">Déroulement</Typography>
            <div/>
            
            <div/>
            <FormattedDateField source="at_pick_time" format="format_date_time" label="Chauffeur sur place" className={classes.inlineBlock}/>
            <FormattedDateField source="start_time" format="format_date_time" label="Démarrage" className={classes.inlineBlock}/>
            
            <div/> */}
            {/* <FormattedDateField source="end_time" format="format_date_time" label="Heure d'arrivée"/> */}

            {/* <TextField source="pt_name" label="Mode de paiement"/> */}
            {/* <TextField source="driver.email" label="Chauffeur"/> */}

              {/* <ReferenceField label="Client" source="client_id" reference="client" link="show">
                <TextField source="email" />
            </ReferenceField>

            <ReferenceField label="Chauffeur" source="driver_id" reference="driver" link="show">
                <TextField source="email" />
            </ReferenceField> */}
            {/* <FormattedDateField source="end_time" format="format_date_time" label="Heure d'arrivée" className={classes.inlineBlock}/> */}
        </SimpleShowLayout>
    </Show>
)};


export default ShowOrderRunning;