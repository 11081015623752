import frenchMessages from 'ra-language-french';

export default {
    ...frenchMessages,
    keyboard_arrow_left: "<",
    keyboard_arrow_right: ">",
    no_driver_avail: 'Aucun chauffeur disponbible pour assigner la commande',
    price_exist_already: "La configuration du prix de ce type d'activité et ce type de commande existe déjà",
    promo_code_exist_already: 'Le code remise existe déjà',
    format_date:'DD/MM/YYYY',
    format_date_time:'DD/MM/YYYY HH:mm:ss',
    dashboard: {
        drivers: {
            list: 'Chauffeurs',
            online: 'Chauffeurs en-ligne',
        },
        clients: 'Clients',
        
        driver_companies: 'Société de chauffeurs',
        orders: {
            running: "Courses en cours",
            unassigned: "Réservations à assigner",
        },
    },
   

    name: 'Nom',
    first_name: 'Prénom',
    last_name: 'Nom',
    civility: 'Civilité',
    max_size: 'Taille maximale:',
    menu:{
        dashboard: 'Tableau de bord',
        accounts: "Comptes",
        vehicles: "Véhicules",
        orders: "Courses",
        prices: "Prix",
        services: "Services",
        settings: "Paramètres",
        reporting: "Reporting",
        configuration: "Configurations",
    },
    // pos: {
    //     search: 'Rechercher',
    //     configuration: 'Configuration',
    //     language: 'Langue',
    //     theme: {
    //         name: 'Theme',
    //         light: 'Clair',
    //         dark: 'Obscur',
    //     },
    //     dashboard: {
    //         monthly_revenue: 'CA à 30 jours',
    //         new_orders: 'Nouvelles commandes',
    //         pending_reviews: 'Commentaires à modérer',
    //         new_customers: 'Nouveaux clients',
    //         pending_orders: 'Commandes à traiter',
    //         order: {
    //             items:
    //                 'par %{customer_name}, un poster |||| par %{customer_name}, %{nb_items} posters',
    //         },
    //         welcome: {
    //             title: 'Bienvenue sur la démo de react-admin',
    //             subtitle:
    //                 "Ceci est le back-office d'un magasin de posters imaginaire. N'hésitez pas à explorer et à modifier les données. La démo s'exécute en local dans votre navigateur, et se remet à zéro chaque fois que vous rechargez la page.",
    //             aor_button: 'Site web de react-admin',
    //             demo_button: 'Code source de cette démo',
    //         },
    //     },
    //     menu: {
    //         sales: 'Ventes',
    //         catalog: 'Catalogue',
    //         customers: 'Clients',
    //     },
    // },

    
    
    resources: {
        rate_system:{
            name: 'Avis',
            list: 'Avis',
            
        },
           gain:{
            name: 'Gains à payer',
            list: 'Gains à payer',
            
        },

        cancel_category:{
            name: "Motif d'annulation",
            list: "Motifs d'annulation",
            create: "Créer un motif d'annulation",
            show: "Motif d'annulation",
            edit: "Éditer motif d'annulation",
        },

        promo_code:{
            name: 'Codes de réduction',
            list: 'Codes de réduction',
            create: 'Créer un code de réduction',
            show: 'Code de réduction',
            edit: 'Éditer code de réduction',
        },

        service_type:{
            name: "Activités des entreprises",
            list: "Activités des entreprises",
            create: "Créer une activité des entreprises",
            show: "Activité des entreprises",
            edit: 'Editer activité des entreprises',
        },

        notice:{
            name: 'Messages',
            list: 'Messages',
            create: 'Créer un message',
            show: 'Message',
            edit: 'Editer message',
        },

        email_template:{
            name: "Templates d\'email",
            list: "Templates d\'email",
            create: "Créer un template d'\email",
            show: "Template d\'email",
            edit: "Editer template d\'email",
        },

        sms_template:{
            name: "Templates de SMS",
            list: "Templates de SMS",
            create: "Créer un template de SMS",
            show: "Template de SMS",
            edit: "Editer template de SMS",
        },

        driver:{
            name: 'Chauffeurs',
            list: 'Chauffeurs',
            create: 'Créer un chauffeur',
            show: 'Chauffeur',
            edit: 'Éditer le chauffeur',
        },

        driver_company:{
            name: 'Chauffeurs flotte',
            list: 'Chauffeurs flotte',
            create: 'Créer un chauffeur flotte',
            show: 'Chauffeur flotte',
            edit: 'Éditer chauffeur flotte',
        },

        driver_deleted:{
            name: 'Chauffeurs supprimés',
            list: 'Chauffeurs supprimés',
            show: 'Chauffeur supprimé',
        },

        driver_company_deleted:{
            name: 'Sociétés de chauffeur supprimées',
            list: 'Sociétés de chauffeur supprimées',
            show: 'Société de chauffeur supprimée',
        },

        client:{
            name: 'Clients',
            list: 'Clients',
            create: 'Créer un client',
            show: 'Client',
            edit: 'Éditer un client',
        },

        client_pro:{
            name: 'Clients professionnels',
            list: 'Clients professionnels',
            create: 'Créer un client professionel',
            show: 'Client professionel',
            edit: 'Éditer un client professionel',
        },

        client_deleted:{
            name: 'Clients supprimés',
            list: 'Clients supprimés',
            show: 'Client supprimé',
        },

        location:{
            name: 'Lieux',
            list: 'Lieux',
            create: 'Créer un lieu',
            show: 'Lieu',
            edit: 'Éditer le lieu',
        },

        option:{
            name: 'Options',
            list: 'Options',
            create: 'Créer une option',
            show: 'Option',
            edit: 'Éditer option',
        },

        commission:{
            name: 'Commissions',
            list: 'Commissions',
            create: 'Créer une commission',
            show: 'Commission',
            edit: 'Éditer commission',
        },

        price_subscription:{
            // name: "Prix d'abonnement",
            // list: "Prix d'abonnement",
            // create: "Créer un prix d'abonnement",
            // show: 'Commission',
            // edit: "Éditer prix d'abonnement",
            name: "Abonnements",
            list: "Abonnements",
            create: "Créer un abonnement",
            show: 'Abonnement',
            edit: "Éditer un abonnement",
        },

        price:{
            name: 'Prix',
            list: 'Prix',
            create: 'Créer un prix',
            show: 'Prix',
            edit: 'Éditer prix',
        },

        wait_fee:{
            name: "Frais d'attente",
            list: "Frais d'attente",
            // create: 'Créer un prix',
            show: "Frais d'attente",
            edit: "Éditer un frais d'attente",
        },

        price_base:{
            name: 'Table de prix',
            list: 'Table de prix',
            create: 'Créer un prix de base',
            show: 'Prix de base',
            edit: 'Éditer un prix',
        },

        price_time:{
            name: "Majorations",
            list: "Majorations",
            create: "Créer une majoration/remise d'horaire",
            show: "Majoration/remise d'horaire",
            edit: "Éditer majoration/remise d'horaire",
        },

        price_route:{
            name: "Forfaits route",
            list: "Forfaits route",
            create: "Créer un forfait route",
            show: "Forfait route",
            edit: "Éditer forfait route",
        },

        price_distance:{
            name: "Forfaits distance",
            list: "Forfaits distance",
            create: "Créer un forfait distance",
            show: "Forfait distance",
            edit: "Éditer forfait distance",
        },

        order_running:{
            name: ' Courses en cours',
            list: 'Courses en cours',
            show: 'Course',
            
        },

        order_discount:{
            name: ' Courses à vide',
            list: 'Courses à vide',
            show: 'Course à vide',
            
        },

        order_finished:{
            name: ' Courses terminées',
            list: 'Courses terminées',
            show: 'Courses',
        },

        order_unassigned:{
            name: 'Réservations à assigner',
            list: 'Réservations à assigner',
            show: 'Réservation à assigner',
            
        },

        vehicle:{
            name: 'Véhicules',
            list: 'Véhicules',
            create: 'Créer un véhicule',
            show: 'Véhicule',
            edit: 'Éditer un véhicule',
        },


        vehicle_type:{
            name: 'Types',
            list: 'Types de véhicule',
            create: 'Créer un type de véhicule',
            show: 'Type de véhicule',
            edit: 'Éditer un type de véhicule',
        },

        vehicle_model:{
            name: 'Modèles',
            list: 'Modèles de véhicule',
            create: 'Créer un modèle de véhicule',
            show: 'Modèle de véhicule',
            edit: 'Éditer un modèle de véhicule',
        },

        vehicle_brand:{
            name: 'Marques',
            list: 'Marques de véhicule',
            create: 'Créer une marque de véhicule',
            show: 'Marque de véhicule',
            edit: 'Éditer une marque de véhicule',
        },

        fuel_type:{
            name: 'Types de carburant',
            list: 'Types de carburant',
            create: 'Créer un type de carburant',
            show: 'Type de carburant',
            edit: 'Éditer un type de carburant',
        },

        luggage_type:{
            name: 'Types de bagage',
            list: 'Types de bagage',
            create: 'Créer un type de bagage',
            show: 'Type de bagage',
            edit: 'Éditer un type de bagage',
        },
        
        seat_cover_type:{
            name: 'Types de revêtement sièges',
            list: 'Types de revêtement sièges',
            create: 'Créer un type de revêtement sièges',
            show: 'Type de revêtement sièges',
            edit: 'Éditer un type de revêtement sièges',
        },
        

        // customers: {
        //     name: 'Client |||| Clients',
        //     fields: {
        //         address: 'Rue',
        //         birthday: 'Anniversaire',
        //         city: 'Ville',
        //         commands: 'Commandes',
        //         first_name: 'Prénom',
        //         first_seen: 'Première visite',
        //         groups: 'Segments',
        //         has_newsletter: 'Abonné à la newsletter',
        //         has_ordered: 'A commandé',
        //         last_name: 'Nom',
        //         last_seen: 'Vu le',
        //         last_seen_gte: 'Vu depuis',
        //         latest_purchase: 'Dernier achat',
        //         name: 'Nom',
        //         total_spent: 'Dépenses',
        //         zipcode: 'Code postal',
        //         password: 'Mot de passe',
        //         confirm_password: 'Confirmez le mot de passe',
        //     },
        //     fieldGroups: {
        //         identity: 'Identité',
        //         address: 'Adresse',
        //         stats: 'Statistiques',
        //         history: 'Historique',
        //         password: 'Mot de passe',
        //         change_password: 'Changer le mot de passe',
        //     },
        //     page: {
        //         delete: 'Supprimer le client',
        //     },
        //     errors: {
        //         password_mismatch:
        //             'La confirmation du mot de passe est différent du mot de passe.',
        //     },
        // },
        // commands: {
        //     name: 'Commande |||| Commandes',
        //     amount: '1 commande |||| %{smart_count} commandes',
        //     title: 'Commande n°%{reference}',
        //     fields: {
        //         basket: {
        //             delivery: 'Frais de livraison',
        //             reference: 'Référence',
        //             quantity: 'Quantité',
        //             sum: 'Sous-total',
        //             tax_rate: 'TVA',
        //             total: 'Total',
        //             unit_price: 'P.U.',
        //         },
        //         customer_id: 'Client',
        //         date_gte: 'Emises depuis',
        //         date_lte: 'Emises avant',
        //         nb_items: 'Nb articles',
        //         reference: 'Référence',
        //         returned: 'Annulée',
        //         status: 'Etat',
        //         total_gte: 'Montant minimum',
        //     },
        // },
        // invoices: {
        //     name: 'Facture |||| Factures',
        //     fields: {
        //         id: 'Numéro',
        //         date: 'Date de facture',
        //         customer_id: 'Client',
        //         command_id: 'Commande',
        //         date_gte: 'Emises depuis',
        //         date_lte: 'Emises avant',
        //         address: 'Adresse',
        //         total_ex_taxes: 'Montant HT',
        //         delivery_fees: 'Frais de livraison',
        //         taxes: 'TVA',
        //     },
        // },
        // products: {
        //     name: 'Poster |||| Posters',
        //     fields: {
        //         category_id: 'Catégorie',
        //         height_gte: 'Hauteur mini',
        //         height_lte: 'Hauteur maxi',
        //         height: 'Hauteur',
        //         image: 'Photo',
        //         price: 'Prix',
        //         reference: 'Référence',
        //         stock_lte: 'Stock faible',
        //         stock: 'Stock',
        //         thumbnail: 'Aperçu',
        //         width_gte: 'Largeur mini',
        //         width_lte: 'Margeur maxi',
        //         width: 'Largeur',
        //     },
        //     tabs: {
        //         image: 'Image',
        //         details: 'Détails',
        //         description: 'Description',
        //         reviews: 'Commentaires',
        //     },
        // },
        // categories: {
        //     name: 'Catégorie |||| Catégories',
        //     fields: {
        //         name: 'Nom',
        //         products: 'Produits',
        //     },
        // },
        // reviews: {
        //     name: 'Commentaire |||| Commentaires',
        //     amount: '1 commentaire |||| %{smart_count} commentaires',
        //     relative_to_poster: 'Commentaire sur',
        //     detail: 'Détail du commentaire',
        //     fields: {
        //         customer_id: 'Client',
        //         command_id: 'Commande',
        //         product_id: 'Produit',
        //         date_gte: 'Publié depuis',
        //         date_lte: 'Publié avant',
        //         date: 'Date',
        //         comment: 'Texte',
        //         status: 'Statut',
        //         rating: 'Classement',
        //     },
        //     action: {
        //         accept: 'Accepter',
        //         reject: 'Rejeter',
        //     },
        //     notification: {
        //         approved_success: 'Commentaire approuvé',
        //         approved_error: 'Erreur: Commentaire non approuvé',
        //         rejected_success: 'Commentaire rejeté',
        //         rejected_error: 'Erreur: Commentaire non rejeté',
        //     },
        // },
        // segments: {
        //     name: 'Segments',
        //     fields: {
        //         customers: 'Clients',
        //         name: 'Nom',
        //     },
        //     data: {
        //         compulsive: 'Compulsif',
        //         collector: 'Collectionneur',
        //         ordered_once: 'A commandé',
        //         regular: 'Régulier',
        //         returns: 'A renvoyé',
        //         reviewer: 'Commentateur',
        //     },
        // },
    },
};
