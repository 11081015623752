
import React from 'react';
import { number, NumberInput, Pagination, TextField, ReferenceManyField, Datagrid, FormTab, TabbedForm, ReferenceInput, TextInput, Edit, SimpleForm, SelectInput, required, useTranslate } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import { EditButton, ListButton, Toolbar, SaveButton, DeleteButton } from 'react-admin';
import { makeStyles, withStyles } from '@material-ui/core/styles';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import { ValidateField } from '../../control/field';
// import {AddPriceTimeButton} from '../../button/field';
import Typography from '@material-ui/core/Typography';

import * as Constant from '../../util/constant';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    
});
const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbarNoStyle = ({permissions, ...props}) => (
    <Toolbar {...props}>
        <SaveButton />
        {/* {permissions === 'Admin' &&
        <DeleteButton label="ra.action.delete" redirect='list' {...props}/>} */}
    </Toolbar>
);

const CustomToolbar = withStyles(toolbarStyles)(CustomToolbarNoStyle);

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const EditActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath}/>
    </CardActions>
);

const Title = ({ record }) => {
    // var string = "";
    // if(record){
    //     const vehicleTypeName = record ? record.vehicle_type_name : '';
    //     const orderType = record ? record.order_type : 0;
    //     if(orderType == OrderType.IMMEDIATE)
    //         string = vehicleTypeName + " - Immédiate";
    //     else
    //         string = vehicleTypeName + " - Réservation à l'avance";
    // }
    const translate = useTranslate();
    // return <span>{translate('resources.price.show')} pour: {record ? `${string}` : ''}</span>;
    return <span>{translate('resources.price.show')}</span>;
    
};

const validatePricePositive = (value, allValues) => {
    if (value <= 0) {
        return 'Doit être plus grand que 0';
    }
    return [];
};

const validatePriceNotNegative = (value, allValues) => {
    if (value < 0) {
        return 'Doit ne pas être moins que 0';
    }
    return [];
};

const validatePrice = [required(), number(), validatePricePositive];
const validatePriceAdditional = [required(), number(), validatePriceNotNegative];

// var activityTypes = [];
// activityTypes.push({id: ACTIVITY_TYPE_TAXI, name: "Taxi"});
// activityTypes.push({id: ACTIVITY_TYPE_VTC, name: "VTC"});
// activityTypes.push({id: ACTIVITY_TYPE_TPMR, name: "TPMR"});
const OrderTypeField = ({ record = {}, label}) => {
    return <div>
         <Typography variant="body2">
        {record.order_type == Constant.OrderType.IMMEDIATE? 'Immédiate' : "Réservation à l'avance, Course à vide"}
        </Typography>
    </div>;
}

OrderTypeField.defaultProps = {
    addLabel: true,
};

const PriceTextField = ({ source, record = {}, label}) => {
    if(record['editable'] === "0"){
        return <TextInput source="price" label="Frais" disabled/>;
    }
    // return <span class="MuiTypography-root MuiTypography-body2">{record['price']}</span>;
    return <TextInput source="price" label="Frais (€/mn)" validate={validatePriceAdditional}/>;
}
    
PriceTextField.defaultProps = {
    addLabel: true,
};

const EditPrice = ({permissions, ...props })  => {
    const classes = useStyles();
    return (
    <Edit title={<Title />} {...props} actions={<EditActions/>}>
         {/* <TabbedForm redirect="list" 
                toolbar={<CustomToolbar/>}>
                    <FormTab label="Configuration de base"> */}
                    <SimpleForm redirect="list" toolbar={<CustomToolbar/>}>
                    <OrderTypeField source="order_type" label="Type de commande"/>
            <ValidateField source="type" label="Gares/Aéroports ?"/>
            {/* <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" link="">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Gamme de véhicule" source="vehicle_class_id" reference="vehicle_class" link="">
                    <TextField source="name" />
                </ReferenceField> */}
                <TextField source="name" label = "Nom"/>
                <PriceTextField source="price" label = "Frais"/>
                        {/* <SelectInput source="activity_type" label="Type d'activité" validate={required()} 
                            choices={orderActivityTypes} className={classes.inlineBlock}/> */}
                        {/* <SelectInput source="order_type" label="Type de commande" validate={required()} 
                            choices={orderTypes} className={classes.inlineBlock} disabled/>
                        
                        <div/>
                        <ReferenceInput source="vehicle_type_id" perPage={99999} className={classes.inlineBlock}
                        reference="vehicle_type" label="Type de véhicule" disabled>
                            <SelectInput optionText="name"/>
                        </ReferenceInput>
                        <ReferenceInput source="vehicle_class_id" perPage={99999} className={classes.inlineBlock}
                        reference="vehicle_class" label="Gamme de véhicule" disabled>
                            <SelectInput optionText="name"/>
                        </ReferenceInput>
                        <TextInput source="base" label="Base(€)" validate={validatePriceAdditional} className={classes.inlineBlock}/>
                        <div/>
                        <TextInput source="min" label="Minimum commissionné (€)" validate={validatePriceAdditional} className={classes.inlineBlock}/>
                        <TextInput source="min_sub" label="Minimum abonné (€)" validate={validatePriceAdditional} className={classes.inlineBlock}/>
                        <div/>
                        <TextInput source="per_km" label="Par km commissionné (€)" validate={validatePriceAdditional} className={classes.inlineBlock}/>
                        <TextInput source="per_km_sub" label="Par km abonné (€)" validate={validatePriceAdditional} className={classes.inlineBlock}/>
                        <div/>
                        <TextInput source="per_minute" label="Par minute commissionné (€)" validate={validatePriceAdditional} className={classes.inlineBlock}/>
                        <TextInput source="per_minute_sub" label="Par minute abonné (€)" validate={validatePriceAdditional} className={classes.inlineBlock}/>
                        <div/> */}
                        {/* <TextInput source="wait" label="Frais d'attente (€/minute)" validate={validatePriceAdditional}/> 
                        <TextInput source="free_wait_time" label="Attente gratuite (minute)" validate={validatePriceAdditional}/>  */}
                        {/* <TextInput source="wait" label="Attente(€/minute)" validate={validatePriceAdditional}/> */}
                        {/* <TextInput source="markup" label="Supplément(€)" validate={validatePriceAdditional}/> */}
                        {/* <TextInput source="markup" label="Supplément fixe(€)" validate={validatePriceAdditional}/>
            <TextInput source="quantity_markup" label="Supplément > 3 passagers(€/pax)" validate={validatePriceAdditional} style={{'width':'50%'}}/> */}
                    </SimpleForm>
                {/* </FormTab>
                <FormTab label="Majorité/réduction d'horaire">
                    <ReferenceManyField pagination={<Pagination />} reference="price_time" target="price_id" addLabel={false}>
                        <Datagrid>
                            <ValidateField source="every_day" label="Tous les jours"/>
                            <TextField source="day" label="Dates"/>
                            <TextField source="markup" label="Majorité/Réduction"/>
                            <TextField source="from_time" label="Heure de début"/>
                            <TextField source="to_time" label="Heure de fin"/>
                            <EditButton basePath="/price_time"/>
                        </Datagrid>
                    </ReferenceManyField>
                    

                </FormTab>
                <FormTab label="Forfait">
                    <ReferenceManyField pagination={<Pagination />} reference="price_route" target="price_id" addLabel={false}>
                        <Datagrid expandHeader={<div/>}>
                            <TextField source="from_id" label="From"/>
                            <TextField source="to_id" label="To"/>
                            <TextField source="per_package" label="Prix(€)"/>
                            
                            
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab> */}
        {/* </TabbedForm> */}
    </Edit>
)};

export default EditPrice;