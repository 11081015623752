import Create from './create' 
import Edit from './edit' 
import List from './list' 
import Show from './show' 
import ClientIcon from '@material-ui/icons/EmojiPeople';

export default{
    // create: Create,
    edit: Edit, 
    list: List,
    show: Show,
    icon: ClientIcon,
}