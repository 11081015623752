
import React from 'react';
import { TextInput, validate, minValue, NumberInput, Edit, ImageField, SimpleForm, number, required, SelectInput, RadioButtonGroupInput, ImageInput, useTranslate } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import { ListButton, Toolbar, SaveButton } from 'react-admin';
import DeleteButton from '../../control/button/delete-button';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { DateInput } from 'react-admin-date-inputs2';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import {locationTypes} from '../../util/variant'

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbarNoStyle = ({permissions, ...props}) => (
    <Toolbar {...props}>
        <SaveButton />
        {/* <DeleteButton label="ra.action.delete" redirect='list' {...props}/> */}
    </Toolbar>
);
const CustomToolbar = withStyles(toolbarStyles)(CustomToolbarNoStyle);

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const EditActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath}/>
    </CardActions>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.option.edit')}: {record ? `${record.name}` : ''}</span>;
};

const validatePricePositive = (value, allValues) => {
    if (value <= 0) {
        return 'Doit être plus grand que 0';
    }
    return [];
};

const validatePriceNotNegative = (value, allValues) => {
    if (value < 0) {
        return 'Doit ne pas être moins que 0';
    }
    return [];
};

const validatePrice = [required(), number(), validatePricePositive];
const validatePriceAdditional = [required(), number(), validatePriceNotNegative];

const EditOption = ({classes, permissions, ...props })  => {
    const translate = useTranslate();
    return (
        <Edit title={<Title />} {...props} actions={<EditActions/>}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar/>}>
        
            <TextInput source="name" label = "Nom" validate={required()}/>
            <TextInput source="price" label="Prix(€)" validate={validatePriceAdditional}/>
            <RadioButtonGroupInput source="is_locked" label="Verrouillé" choices={[
                { id: '0', name: 'Non' },
                { id: '1', name: 'Oui' },
            ]} initialValue='0'/>

        </SimpleForm>
    </Edit>
)};

export default EditOption;