
import React, {Link} from 'react';
import { Labeled, Pagination, ReferenceField, SimpleShowLayout,TextField, Show, useTranslate, ImageField, TabbedShowLayout, Tab, UrlField,
    ReferenceManyField, Datagrid} from 'react-admin';
import { EditButton, ListButton } from 'react-admin';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {BackButton, DeleteButton, CustomerLockButton, CustomerUnlockButton} from '../../control/button/index';
import ImageViewer from '../../control/viewer/image-viewer';
import {OrderStatusField, FormattedDateField, AvatarField, FullNameField, FullNameDriverField, FullNameClientField,
    GenderField, GenderFilterField, LockField, ValidateField, DocNameField, FileCountField, DriverDataField} from '../../control/field/index'
import {Status, FALSE, TRUE, ANIMAL_TYPE_FREE, ANIMAL_TYPE_CAGE, USER_TYPE_CLIENT_PRO} from '../../util/constant'
// import {
//     Icon_Visa,
//     Icon_MasterCard
//   } from 'material-ui-credit-card-icons';
  
import {BACKEND_FILE_URL} from '../../util/variant'

import { makeStyles } from '@material-ui/core/styles';
import { bool } from 'prop-types';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorLabel: { paddingLeft: 50, color: '#c30040',},

    colorPrimary: {color:'#c30040',},
    
    noColorLabel: {paddingLeft: 50,  
    },

    colorValue: {color: '#c30040',paddingLeft:5},

    supplementText: {color: '#c30040',},

    noHeader:{display:'none',
    // height:'1px'
    },

    image:{width:'200px',height:'200px',}
    
});

const styles = {
    flex: { display: 'flex'},
    images:{display: 'flex', paddingLeft:'1em', marginLeft:"3em" },
    flexColumn: { display: 'flex', flexDirection: 'column', },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    map:{ flex: 1, marginTop: '1em', marginBottom: '1em' },
    button:{ flex: 1, marginTop: '1em'},
};


const cardActionStyle = {
    // zIndex: 2,
    // display: 'inline-block',
    // float: 'right',
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};




const ShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        {/* <EditButton basePath='/my-profile' record={data} /> */}
        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={changePassword}>CHANGER LE MOT DE PASSE</Button> */}
        {/* <Link to="/change-password">Changer le mot de passe</Link> */}
        <ListButton basePath={basePath}/>
        {/* <BackButton/> */}
        {/* <EditButton basePath={basePath} record={data} /> */}
        
        {typeof data != 'undefined' &&  typeof data.locked != 'undefined' && data.locked === Status.LOCKED &&
            <CustomerUnlockButton basePath={basePath} record={data} resource={resource}/>
        }
        {typeof data != 'undefined' &&  typeof data.locked != 'undefined' && data.locked === Status.UNLOCKED &&
            <CustomerLockButton basePath={basePath} record={data} resource={resource}/>
        }
        {/* <EditButton basePath={basePath} record={data} /> */}
        <DeleteButton resource="client" basePath={basePath} record={data} content="Êtes-vous sûr de vouloir supprimer ce client? Toutes les donnnées personnelles de ce client seront supprimées"/>
    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.client.show')}: {record ? `${record.email}` : ''}</span>;
};

const CardNumberField = ({record, label}) => {
    // const classes = useStyles();
    // const data = JSON.parse(record.data);
    console.log('Card ' + JSON.stringify(record));
    console.log('Car data ' + JSON.stringify(record.data));
    return <div><span>{'xxxx xxxx xxxx ' + record.data.last4}</span></div>    
    // return <div></div>
}

const ExpiryField = ({record, label}) => {
    // const classes = useStyles();
    // const data = JSON.parse(record.data);
//    console.log('Card ' + JSON.stringify(record));
  //  console.log('Car data ' + JSON.stringify(record.data));
    if(typeof(record) !== 'undefined' && typeof(record.data) !== 'undefined')
        return <div><span>{record.data.expiry_month + '/' + record.data.expiry_year}</span></div>    
    return <div></div>
}

const ClientTypeField = ({ record }) => {
    return <span>{record.user_type == USER_TYPE_CLIENT_PRO ? 'Client professionnel' : 'Client particulier'}</span>;
};

ClientTypeField.defaultProps = {
    addLabel: true,
};



const DocContentPanel = ({ id, record, resource }) => {
    const classes = useStyles();
    console.log("File record " + JSON.stringify(record));
    var files = record.files;
    files.forEach(function(file, index){
        if(!file.file_url.startsWith("http://") && !file.file_url.startsWith("https://")){
            file.file_url = BACKEND_FILE_URL + file.file_url;
            files[index] = file;
        }
    }
    , files);

    if(files.length === 0)
        return <div>
        {/* <ValidityDateField record={record}/> */}
        <Typography variant="body2" className={classes.colorLabel}>Aucun fichier</Typography>
        </div>;
    
    return(
        <div style={styles.flexColumn}>
            <Typography variant="body2" className={classes.noColorLabel}>Fichier:</Typography>
            <br/>
            <div style={styles.images}>        
                {files.map(file => {
                    return <ImageViewer files={files} fileUrl={file.file_url} title="" width='300' height='300' className={classes.noColorLabel}/>
                })}
            </div>
            {/* <ValidityDateField record={record}/> */}
            {/* <div className={classes.noColorLabel}><span>Actions: </span> */}
                {/* {record.date !== '' && <ĐocDateEditButton record={record}/>} */}
                {/* <DocRejectButton record={record}/><DocApproveButton record={record}/></div> */}

        </div>
        
    )
};

// const CardTypeField = ({record, label}) => {
//     if(typeof(record) !== 'undefined' && typeof(record.data) !== 'undefined'){
//         if(record.data.card_type == "Visa")
//             return <Icon_Visa/>
        
//         if(record.data.card_type == "MasterCard")
//             return <Icon_MasterCard/>

//         return <span>{record.data.card_type}</span>
//     }
//     return <span>"Inconnu"</span>
// }

// const userId = localStorage.getItem('user_id');
// const role = localStorage.getItem('role');
const PhotoField = ({ record = {}, source, label}) => {
    // const classes = useStyles();
    return <ImageViewer files={[]} fileUrl={record[source]} title={label} width='60' height='60' round='50%'/>
        
}

// const AnimalTypeField = ({record, label}) => {
//     // const classes = useStyles();
//     // const data = JSON.parse(record.data);
// //    console.log('Card ' + JSON.stringify(record));
//   //  console.log('Car data ' + JSON.stringify(record.data));
//     if(typeof(record) !== 'undefined' && typeof(record.data) !== 'undefined')
//         return <div><span>{record. + '/' + record.data.expiry_year}</span></div>    
//     return <div></div>
// }


const orderRowClick = (id, basePath, record) => '#/order/' + record.id;

const ShowClient = ({staticContext, ...props}) => {
    const translate = useTranslate();
    const classes = useStyles();
    return(
    <Show title={<Title />} {...props} actions={<ShowActions />}>
            <TabbedShowLayout redirect="list">
            <Tab label="Profil">
                <AvatarField source="photo_url" label="" size='160'/>
                <ReferenceField label="Activité de l'entreprise" source="service_type_id" reference="service_type" link="">
                    <TextField source="name" />
                </ReferenceField>
                {/* <PhotoField source="photo_url" label=""/> */}
                {/* <ImageField source="photo_url" label="" className="avatar"/> */}
                
                {/* <TextField source="reg_code" label="Chauffeur parrain"/> */}
                {/* <ClientTypeField label="Type de client"/> */}
                {/* <TextField source="user_number" label="ID"/> */}
                {/* <TextField source="id" label="ID"/> */}
                {/* <GenderField label={translate('civility')}/> */}
                <div/>
                <TextField source="last_name" label="Nom" className={classes.inlineBlock}/>
                <TextField source="first_name" label="Prénom"className={classes.inlineBlock}/>
                {/* <br/> */}
                <div/>
                <TextField source="mobile" label = "Mobile" className={classes.inlineBlock}/>
                <TextField source="email" label = "Email" className={classes.inlineBlock}/>
                {/* <br/> */}

                {/* <FormattedDateField source="birth_date" label="Date de naissance"  locales="fr-FR"/> */}
                {/* <TextField source="address" label="Adresse" className={classes.inlineBlock}/> */}
                {/* <TextField source="postal_code" label="Code postal" className={classes.inlineBlock}/> */}
                {/* <TextField source="city" label="Ville" className={classes.inlineBlock}/> */}
                <div/>
            {/* <VerifyField source="activated" label="Mobile vérifié?"/> */}
            {/* <TextField source="address" label="Adresse postale"/>

            <TextField source="country" label="Pays" className={classes.inlineBlock}/>
            <TextField source="nationality" label="Nationalité" className={classes.inlineBlock}/> */}
            {/* <TextField source="wallet.balance" label="Portefeuille(€)" className={classes.inlineBlock}/> */}

            <FormattedDateField source="create_time" label="Date d'inscription"  locales="fr-FR"/>
                
                {/* <br/> */}
                {/* <Divider/> */}
                {/* <br/> */}
                {/* <TextField source="activation_code" label="Code d'activation"/> */}

                <ValidateField source="locked" label='Verrouillé'/>
            </Tab>

            <Tab label="Documents">
            <ReferenceManyField reference="doc" target="user_id" addLabel={false}>
                    <Datagrid expand={<DocContentPanel/>} expandHeader={<div/>}>
                        <DocNameField label=""/>
                        
                    </Datagrid>
                </ReferenceManyField>
            </Tab>

            {/* <Tab label="Cartes de paiement">
                <ReferenceManyField pagination={<Pagination />} reference="card" target="user_id" addLabel={false}>
                    <Datagrid>
                        <CardNumberField label='Numéro de la carte'/>  
                        <ExpiryField label="Expiration"/>
                        <TextField source="data.card_type" label="Type"/>
                        <ValidateField source="is_default" label="Par défaut"/>      
                    </Datagrid>
                </ReferenceManyField>
                
            </Tab> */}
            
            <Tab label="Commandes">
                <ReferenceManyField pagination={<Pagination />} reference="order_client" target="client_id" addLabel={false}>
                        <Datagrid rowClick="">
                        <TextField source="create_time" label="Date/heure"/>
                        <TextField source="order_number" label="Nº de commande"/>
                        <TextField source="from_address" label="Adresse de départ"/>
                        <TextField source="to_address" label="Adresse d'arrivée"/>
                        <FullNameDriverField label="Chauffeur"/>
                        <TextField source="total_entire" label="Prix(€)"/>
                        <OrderStatusField source="status" label = "État"/>
                        {/* <TextField source="rating_on_client" label="Note attribué par le chauffeur"/> */}
                        </Datagrid>
                    </ReferenceManyField>
            </Tab>
            </TabbedShowLayout>
    </Show>);
}


export default ShowClient;