// import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { userLogin, LoginForm } from 'react-admin';
// import { MuiThemeProvider } from '@material-ui/core/styles';

// class LoginPage extends Component {
//     submit = (e) => {
//         e.preventDefault();
//         // gather your data/credentials here
//         const credentials = { };

//         // Dispatch the userLogin action (injected by connect)
//         this.props.userLogin(credentials);
//     }

//     render() {
//         return (
//             <MuiThemeProvider theme={this.props.theme}>
//                 <form onSubmit={this.submit}>
//                     <LoginForm/>
//                 </form>
//             </MuiThemeProvider>
//         );
//     }
// };

// export default connect(undefined, { userLogin })(LoginPage);
import { connect } from 'react-redux';
import { userLogin, LoginForm, Notification } from 'react-admin';

import React, {
    Component,
} from 'react';

import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import {
    MuiThemeProvider,
    createMuiTheme,
    withStyles,
    createStyles,
    WithStyles,
    Theme,
} from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';

const styles = (theme) =>
    createStyles({
        main: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            height: '1px',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            // backgroundImage: 'radial-gradient(circle at 50% 14em, #A09A86, #A09A86 60%, #A09A86)',
            backgroundImage: 'linear-gradient(to right, #39E89B, #FECA47)',
        },
        card: {
            minWidth: 300,
            // marginTop: '6em',
        },
        avatar: {
            margin: '1em',
            display: 'flex',
            justifyContent: 'center',
        },
        icon: {
            backgroundColor: theme.palette.secondary[500],
        },
    });

/**
 * A standalone login page, to serve as authentication gate to the admin
 *
 * Expects the user to enter a login and a password, which will be checked
 * by the `authProvider` using the AUTH_LOGIN verb. Redirects to the root page
 * (/) upon success, otherwise displays an authentication error message.
 *
 * Copy and adapt this component to implement your own login logic
 * (e.g. to authenticate via email or facebook or anything else).
 *
 * @example
 *     import MyLoginPage from './MyLoginPage';
 *     const App = () => (
 *         <Admin loginPage={MyLoginPage} authProvider={authProvider}>
 *             ...
 *        </Admin>
 *     );
 */

var Logo = require('../../res/logo.png');

class CustomLogin extends Component{
    theme = createMuiTheme(this.props.theme);
    containerRef = React.createRef();
    backgroundImageLoaded = false;

    // updateBackgroundImage = () => {
    //     if (!this.backgroundImageLoaded && this.containerRef.current) {
    //         const { backgroundImage } = this.props;
    //         this.containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
    //         this.backgroundImageLoaded = true;
    //     }
    // };

    // // Load background image asynchronously to speed up time to interactive
    // lazyLoadBackgroundImage() {
    //     const { backgroundImage } = this.props;

    //     if (backgroundImage) {
    //         const img = new Image();
    //         img.onload = this.updateBackgroundImage;
    //         img.src = backgroundImage;
    //     }
    // }

    componentDidMount() {
        // this.lazyLoadBackgroundImage();
    }

    componentDidUpdate() {
        // if (!this.backgroundImageLoaded) {
        //     this.lazyLoadBackgroundImage();
        // }
    }

    render() {
        const {
            backgroundImage,
            classes,
            className,
            loginForm,
            staticContext,
            ...rest
        } = this.props;

        return (
            <MuiThemeProvider theme={this.theme}>
                <div
                    className={classnames(classes.main, className)}
                    {...rest}
                    ref={this.containerRef}
                >
                    <img src={Logo} alt="Logo" style={{'margin-bottom':'20px'}}/>
                    <Card className={classes.card}>
                        <div className={classes.avatar}>
                            <Avatar className={classes.icon}>
                                <LockIcon />
                            </Avatar>
                        </div>
                        <LoginForm/>
                    </Card>
                    <Notification />
                </div>
            </MuiThemeProvider>
        );
    }
}

const Login = withStyles(styles)(CustomLogin);

// EnhancedLogin.propTypes = {
//     // backgroundImage: PropTypes.string,
//     loginForm: PropTypes.element,
//     theme: PropTypes.object,
//     staticContext: PropTypes.object,
// };

// EnhancedLogin.defaultProps = {
//     // backgroundImage: 'https://source.unsplash.com/random/1600x900/daily',
//     theme: defaultTheme,
//     loginForm: <LoginForm />,
// };
// export default EnhancedLogin;

export default connect(undefined, { userLogin })(Login);
