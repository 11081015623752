
import React from 'react';
import {SelectInput, TextInput, ImageInput, ImageField, Edit, SimpleForm, required, useTranslate } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import { ListButton, Toolbar, SaveButton, DeleteButton } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { DateInput } from 'react-admin-date-inputs2';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import {GENDER_MALE, GENDER_FEMALE} from '../../util/constant';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbarNoStyle = ({permissions, ...props}) => (
    <Toolbar {...props}>
        <SaveButton />
        {/* {permissions === 'Admin' &&
        <DeleteButton label="ra.action.delete" redirect='list' {...props}/>} */}
    </Toolbar>
);
var genders = [];
genders.push({id: GENDER_MALE, name: 'Monsieur'});
genders.push({id: GENDER_FEMALE, name: "Madame"});

const CustomToolbar = withStyles(toolbarStyles)(CustomToolbarNoStyle);

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const EditActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath}/>
    </CardActions>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.client_pro.edit')}: {record ? `${record.email}` : ''}</span>;
};

const EditClient = ({classes, permissions, ...props })  => {
    const translate = useTranslate();
    const uploadFileText = translate('ra.input.file.upload_single') + '(' + translate('max_size') + '100KB)';
    return(
    <Edit title={<Title />} {...props} actions={<EditActions/>} undoable={false}>
         <SimpleForm redirect="show" 
                toolbar={<CustomToolbar permissions={permissions} />}>
                <TextInput source="email" label="Email" validate={required()}/>
                <TextInput source="mobile_dial_code" label="Indicatif du pays" validate={required()}/>
            <TextInput source="mobile" label="Mobile" validate={required()}/>
            <TextInput source="reg_code" label="Chauffeur préférentiel"/>
            

        </SimpleForm>
    </Edit>
)};

export default EditClient;