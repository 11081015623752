
import React from 'react';
import { TextInput, RadioButtonGroupInput, Create, SimpleForm, SelectInput, required, number, useTranslate} 
from 'react-admin';
// import { DateInput } from 'react-admin-date-inputs2';
// import { DateInput } from 'react-admin-date-inputs2';
// import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
// import frLocale from "date-fns/locale/fr";

// DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth

// import Strings from '../res/strings';
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.option.create')}</span>;
};

const validatePricePositive = (value, allValues) => {
    if (value <= 0) {
        return 'Doit être plus grand que 0';
    }
    return [];
};

const validatePriceNotNegative = (value, allValues) => {
    if (value < 0) {
        return 'Doit ne pas être moins que 0';
    }
    return [];
};

const validatePrice = [required(), number(), validatePricePositive];
const validatePriceAdditional = [required(), number(), validatePriceNotNegative];

const CreateOption = props => {
    const translate = useTranslate();
    
    return(
    <Create title={<Title/>} {...props}>
        <SimpleForm redirect="list">
            <TextInput source="name" label = {translate('name')} validate={required()}/>
            <TextInput source="price" label="Prix(€)" validate={validatePriceAdditional}/>
            <RadioButtonGroupInput source="is_locked" label="Verrouillé?" 
            choices={[
                { id: '0', name: 'Non' },
                { id: '1', name: 'Oui' },
            ]} initialValue='0'/>
        </SimpleForm>
    </Create>
)};

export default CreateOption;
