import React from 'react';
import Typography from '@material-ui/core/Typography';
import { userTranslate } from 'react-admin'
import { OrderStatus } from '../../util/constant';


export var orderDiscountStatusValues = [];
orderDiscountStatusValues.push({ id: 0, name: "Tous" });
orderDiscountStatusValues.push({ id: OrderStatus.CREATED, name: "Créée" });
orderDiscountStatusValues.push({ id: OrderStatus.ASSIGNED, name: "Prix donné par l'admin" });
orderDiscountStatusValues.push({ id: OrderStatus.ACCEPTED, name: "Prix accepté par chauffeur" });

export const OrderDiscountStatusFilterField = ({ record }) => {
    var statusText = 'Tous';
    const status = parseInt(record.id);
    if (status & OrderStatus.ACCEPTED)
        statusText = "Prix accepté par chauffeur";
    else
        if (status & OrderStatus.ASSIGNED)
            statusText = "Prix donné par l'admin";
        else
            if (status & OrderStatus.CREATED) {
                statusText = "Créée"
            }

    return <div>
        <Typography variant="body2">
            {statusText}
        </Typography>
    </div>;
};

export var orderRunningStatusValues = [];
orderRunningStatusValues.push({ id: 0, name: "Tous" });
orderRunningStatusValues.push({ id: OrderStatus.CREATED, name: "En attente" });
orderRunningStatusValues.push({ id: OrderStatus.ASSIGNED, name: "En attente de chauffeur" });
orderRunningStatusValues.push({ id: OrderStatus.ACCEPTED, name: "Acceptée par chauffeur" });
orderRunningStatusValues.push({ id: OrderStatus.TO_PICK, name: "En route pour la prise en charge" });
orderRunningStatusValues.push({ id: OrderStatus.AT_PICK, name: "À la prise en charge" });
orderRunningStatusValues.push({ id: OrderStatus.ONBOARD, name: "Passager à bord" });
orderRunningStatusValues.push({ id: OrderStatus.STARTED, name: "Commencée" });
orderRunningStatusValues.push({ id: OrderStatus.ENDED, name: "Arrivée" });

export var orderFinishedStatusValues = [];
orderFinishedStatusValues.push({ id: 0, name: "Tous" });
orderFinishedStatusValues.push({ id: OrderStatus.DENIED, name: "Aucun chauffeur" });
orderFinishedStatusValues.push({ id: OrderStatus.FINISHED, name: "Complétée" });
orderFinishedStatusValues.push({ id: OrderStatus.CANCELLED, name: "Annulée" });


export const OrderRunningStatusField = ({ record }) => {
    var statusText = 'Tous';
    const status = parseInt(record.status);
    const orderSubType = record.order_sub_type;
    const manualDispatching = record.manual_dispatching;
    if (status & OrderStatus.ENDED)
        statusText = "Arrivée";
    else
        if (status & OrderStatus.STARTED)
            statusText = "Débutée";
        else
            if (status & OrderStatus.ONBOARD)
                statusText = "Passager à bord";
            else
                if (status & OrderStatus.TO_PICK)
                    statusText = "En route pour la prise en charge";
                else
                    if (status & OrderStatus.AT_PICK)
                        statusText = "À la prise en charge";
                    else
                        if (status & OrderStatus.ACCEPTED)
                            statusText = "Acceptée par chauffeur";
                        else
                            if (status & OrderStatus.ASSIGNED)
                                statusText = "En attente dechauffeur";
                            else
                                if (status & OrderStatus.CREATED) {
                                    if (orderSubType === "1") {
                                        if (manualDispatching === "0")
                                            statusText = "Pas encore validée";
                                        else
                                            statusText = "Validée"
                                    }
                                    else
                                        statusText = "En attente du chauffeur"
                                }

    return <div>
        <Typography variant="body2">
            {statusText}
        </Typography>
    </div>;
};


export const OrderDiscountStatusField = ({ record }) => {
    var statusText = 'Tous';
    const status = parseInt(record.status);
    const orderSubType = record.order_sub_type;
    const manualDispatching = record.manual_dispatching;

    if (status & OrderStatus.ACCEPTED)
        statusText = "Prix accepté par chauffeur";
    else
        if (status & OrderStatus.ASSIGNED)
            statusText = "Prix donné par l'admin";
        else
            if (status & OrderStatus.CREATED) {
                statusText = "Créée"
            }

    return <div>

        <Typography variant="body2">
            {statusText}
        </Typography>
    </div>;
};
OrderDiscountStatusField.defaultProps = {
    addLabel: true,
};

export const OrderRunningStatusFilterField = ({ record }) => {
    var statusText = 'Tous';
    const status = parseInt(record.id);
    const orderSubType = record.order_sub_type;
    const manualDispatching = record.manual_dispatching;
    if (status & OrderStatus.ENDED)
        statusText = "Arrivée";
    else
        if (status & OrderStatus.STARTED)
            statusText = "Débutée";
        else
            if (status & OrderStatus.ONBOARD)
                statusText = "Passager à bord";
            else
                if (status & OrderStatus.TO_PICK)
                    statusText = "En route pour la prise en charge";
                else
                    if (status & OrderStatus.AT_PICK)
                        statusText = "Sur la prise en charge";
                    else
                        if (status & OrderStatus.ACCEPTED)
                            statusText = "Acceptée par chauffeur";
                        else
                            if (status & OrderStatus.ASSIGNED)
                                statusText = "En attente de chauffeur";
                            else
                                if (status & OrderStatus.CREATED) {
                                    if (orderSubType === 1) {
                                        if (manualDispatching === 0)
                                            statusText = "Pas encore validée";
                                        else
                                            statusText = "Validée"
                                    }
                                    else
                                        statusText = "En attente du chauffeur"
                                }

    return <div>
        <Typography variant="body2">
            {statusText}
        </Typography>
    </div>;
};

export const OrderFinishedStatusFilterField = ({ record }) => {
    var statusText = 'Tous';
    const status = parseInt(record.id);
    if (status & OrderStatus.CANCELLED)
        statusText = "Annulée";
    else
        if (status & OrderStatus.FINISHED)
            statusText = "Complétée";
        else
            if (status & OrderStatus.DENIED)
                statusText = "Aucun chauffeur";

    return <div>
        <Typography variant="body2">
            {statusText}
        </Typography>
    </div>;
};

const ORDER_CANCEL_BY_CLIENT_BEFORE_ACCEPT = 10;
const ORDER_CANCEL_BY_CLIENT_BEFORE_TO_PICK = 11;
const ORDER_CANCEL_BY_CLIENT_BEFORE_AT_PICK = 12;
const ORDER_CANCEL_BY_CLIENT_BEFORE_ON_BOARD = 13;
const ORDER_CANCEL_BY_CLIENT_BEFORE_START = 14;
const ORDER_CANCEL_BY_CLIENT_BEFORE_END = 15;

const ORDER_CANCEL_BY_DRIVER_BEFORE_TO_PICK = 20;
const ORDER_CANCEL_BY_DRIVER_BEFORE_AT_PICK = 21;
const ORDER_CANCEL_BY_DRIVER_BEFORE_ON_BOARD = 22;
const ORDER_CANCEL_BY_DRIVER_BEFORE_START = 23;
const ORDER_CANCEL_BY_DRIVER_BEFORE_END = 24;
const ORDER_CANCEL_BY_DRIVER_AT_END = 25;
const ORDER_CANCEL_BY_DRIVER_OVER_WAIT = 27;

const ORDER_CANCEL_BY_SYSTEM_NO_DRIVER_AVAIL = 30;
const ORDER_CANCEL_BY_SYSTEM_CLIENT_NOT_CHOOSE_DRIVER = 31;
const ORDER_CANCEL_BY_SYSTEM_NO_DRIVER_ACCEPT = 32;
const ORDER_CANCEL_BY_SYSTEM_CLIENT_ABSENT = 33;
const ORDER_CANCEL_BY_SYSTEM_DRIVER_NO_ACTION = 34;
const ORDER_CANCEL_BY_SYSTEM_DRIVER_NOT_TO_PICK = 35;

const OrderStatusField = ({ record = {}, label }) => {
    var statusText = 'Inconnu';
    const status = parseInt(record.status);

    if (status & OrderStatus.CANCELLED) {
        const cancelType = parseInt(record.cancel_type);

        if (cancelType === ORDER_CANCEL_BY_CLIENT_BEFORE_ACCEPT)
            statusText = "Annulée par client avant l'acceptation";
        else
            if (cancelType === ORDER_CANCEL_BY_CLIENT_BEFORE_TO_PICK)
                statusText = "Annulée par client avant la prise en charge";
            else
                if (cancelType === ORDER_CANCEL_BY_CLIENT_BEFORE_AT_PICK)
                    statusText = "Annulée par client avant la prise en charge";
                else
                    if (cancelType === ORDER_CANCEL_BY_CLIENT_BEFORE_ON_BOARD)
                        statusText = "Annulée par client avant à bord";
                    else
                        if (cancelType === ORDER_CANCEL_BY_CLIENT_BEFORE_START)
                            statusText = "Annulée par client avant le début";
                        else
                            if (cancelType === ORDER_CANCEL_BY_CLIENT_BEFORE_END)
                                statusText = "Annulée par client avant l'arrivée";
                            else
                                if (cancelType === ORDER_CANCEL_BY_DRIVER_BEFORE_TO_PICK)
                                    statusText = "Annulée par chauffeur avant la prise en charge";
                                else
                                    if (cancelType === ORDER_CANCEL_BY_DRIVER_BEFORE_AT_PICK)
                                        statusText = "Annulée par chauffeur avant la prise en charge";
                                    else
                                        if (cancelType === ORDER_CANCEL_BY_DRIVER_OVER_WAIT)
                                            statusText = "Annulée par chauffeur fin d'attente";
                                        else
                                            if (cancelType === ORDER_CANCEL_BY_DRIVER_BEFORE_ON_BOARD)
                                                statusText = "Annulée par chauffeur avant à bord";
                                            else
                                                if (cancelType === ORDER_CANCEL_BY_DRIVER_BEFORE_START)
                                                    statusText = "Annulée par chauffeur avant le début";
                                                else
                                                    if (cancelType === ORDER_CANCEL_BY_DRIVER_BEFORE_END)
                                                        statusText = "Annulée par chauffeur avant l'arrivée";
                                                    else
                                                        if (cancelType === ORDER_CANCEL_BY_DRIVER_AT_END)
                                                            statusText = "Annulée par chauffeur à l'arrivée";
                                                        else
                                                            if (cancelType === ORDER_CANCEL_BY_SYSTEM_CLIENT_ABSENT)
                                                                statusText = "Annulée par le système: Absence du client";
                                                            else
                                                                if (cancelType >= ORDER_CANCEL_BY_SYSTEM_NO_DRIVER_AVAIL)
                                                                    statusText = "Annulée par le système: Pas de chauffeurs";
    }
    else
        if (status & OrderStatus.FINISHED)
            statusText = "Complétée";
        else
            if (status & OrderStatus.ENDED)
                statusText = "Arrivée";
            else
                if (status & OrderStatus.STARTED)
                    statusText = "Débutée";
                else
                    if (status & OrderStatus.ONBOARD)
                        statusText = "Passager à bord";
                    else
                        if (status & OrderStatus.AT_PICK)
                            statusText = "Sur la prise en charge";
                        else
                            if (status & OrderStatus.TO_PICK)
                                statusText = "En route pour la prise en charge";
                            else
                                if (status & OrderStatus.ACCEPTED)
                                    statusText = "Acceptée par chauffeur";
                                else
                                    if (status & OrderStatus.DENIED)
                                        statusText = "Aucun chauffeur";
                                    else
                                        if (status & OrderStatus.ASSIGNED)
                                            statusText = "En attente de chauffeur";
                                        else
                                            if (status & OrderStatus.CREATED)
                                                statusText = "En attente";
    return <div>
        <Typography variant="body2">
            {statusText}
        </Typography>
    </div>;
}

OrderStatusField.defaultProps = {
    addLabel: true,
};

export default OrderStatusField;
