import React, { Component } from 'react'

import '../../App.css'

import PdfShow from './pdf-show'
import ImageShow from './image-show'

import { getUrlExtension } from '../../util/function';


export default class FileShow extends Component {
  constructor(props) {
    super(props);

  }

  render() {
        console.log('File show props:');
        console.log(this.props);
        const {file} = this.props;
        const ext = getUrlExtension(file.url);
        if(ext === 'pdf')
            return (
                <PdfShow file={file}/>
            );
        else
            return (
                <ImageShow file={file}/>
            );
    
  }
}

