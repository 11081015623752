import React, {Fragment} from 'react';
import { CardActions, CreateButton, List, Datagrid, SingleFieldList, ReferenceField, ChipField, TextField, ReferenceArrayField, ImageField, DateField, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import { Route } from 'react-router';
import CreateVehicleBrand from './create';

import BatchDeleteButton from '../../control/button/batch-delete-button';
import {locationDict, locationTypes} from '../../util/variant'
import {ValidateField, AvatarField, FormattedDateField} from '../../control/field/index'

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
    </Fragment>
);

const ListActions = ({ basePath }) => (
       <CardActions>
           <CreateButton basePath={basePath} />
       </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle_brand.list')}</span>;
};

// const TypeTextField = ({ source, record = {}, label}) => {
//     const locationType = locationDict[record.type];
//     return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
// }
    
// TypeTextField.defaultProps = {
//     addLabel: true,
// };

const statusList = [
    {id: 0, name: 'Non-verrouillé'},
    {id: 1, name: 'Verrouillé'}
];

const ListFilter = ({ permissions, ...props })  => (
    <Filter {...props}>
        <TextInput label="Immatriculation" source="plate_number" alwaysOn resettable/>
        {/* <SelectInput label="État" source="is_locked" alwaysOn choices={statusList}
            allowEmpty emptyText="Tous" emptyValue='-1' initialValue='-1'
        /> */}
        
    </Filter>
);

const ListVehicle = ({permissions, ...props}) => (
    <React.Fragment>
        <List title={<Title/>} {...props} actions={<ListActions/>}
            filters = {<ListFilter permissions={permissions} />} 
            exporter={false}>
             <Datagrid rowClick="show">
             {/* <TextField source="plate_number" label="Plaque" className={classes.colorValue} /> */}
             <TextField source="plate_number" label="Immatriculation" />
                        {/* <ReferenceField label="Marque" source="brand_id" reference="vehicle_brand">
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Modèle" source="model_id" reference="vehicle_model">
                            <TextField source="name" />
                        </ReferenceField> */}

                        <TextField source="brand_name" label="Marque"/>
                        <TextField source="model_name" label="Modèle"/>
                        {/* <TextField source="vehicle_type_name" label="Type"/> */}
                        {/* <TextField source="color" label="Couleur"/> */}
                        {/* <TextField source="color" label="Couleur"/> */}
                        <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" link="">
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Gamme de véhicule" source="vehicle_class_id" reference="vehicle_class" link="">
                            <TextField source="name" />
                        </ReferenceField>
                        {/* <ReferenceField label="Type de carburant" source="fuel_type_id" reference="fuel_type" perPage={1000} link="">
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Type de revêtement siège" source="seat_cover_type_id" reference="seat_cover_type" perPage={1000} link="">
                            <TextField source="name" />
                        </ReferenceField> */}
                        
                        {/* <ReferenceArrayField label="Options" reference="option" source="services" perPage={1000} link="">
                                        <SingleFieldList>
                                            <ChipField source="name" />
                                        </SingleFieldList>
                                    </ReferenceArrayField> */}
                        <FormattedDateField source="circulation_date" label="Date de la 1e mise en circulation"  locales="fr-FR"/>
                        <FormattedDateField source="control_date" label="Date du contrôle technique"  locales="fr-FR"/>
                        <ReferenceField label="Chauffeur" source="driver_id" reference="driver" link="" perPage={10000}>
                            <TextField source="email" />
                        </ReferenceField>
            </Datagrid>
        </List>
        {/* <Route
           path="/vehicle_brand/create"
           render={() => (
               <CreateVehicleBrand {...props} />
           )} */}
    
    </React.Fragment>
);

export default ListVehicle;

