import React from 'react';
import {
    ArrayInput, SimpleFormIterator, Edit, NumberInput, TextInput, number, TextField, SimpleForm, required, TabbedForm,
    FormTab, useTranslate
} from 'react-admin';
// import RichTextInput from 'ra-input-rich-text';
import Button from '@material-ui/core/Button';
import { ShowButton, SaveButton, Toolbar, TopToolbar } from 'react-admin';
import Typography from '@material-ui/core/Typography';

const EditActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        {/* <ShowButton basePath='/view-my-profile' record={data} /> */}
        {/* <Button color="primary" onClick={customAction}>Custom Action</Button> */}
    </TopToolbar>
);

const EditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton
            label="Enregistrer"
            redirect="/config"
            submitOnEnter={true}
        />

    </Toolbar>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('menu.settings')}</span>;
};

const validateNotSmallerThanOne = (value, allValues) => {
    if (value < 1) {
        return 'Doit ne pas être moins que 1';
    }
    return [];
};

const validatePositive = (value, allValues) => {
    if (value <= 0) {
        return 'Doit être > 0';
    }
    return [];
};

const validateNotPositive = (value, allValues) => {
    if (value < 0) {
        return 'Doit être >=0 ';
    }
    return [];
};

const smallerThan60 = (value, allValues) => {
    if (value >= 60) {
        return 'Doit être moins que 60';
    }
    return [];
};

const validatePositiveNumber = [required(), number(), validatePositive];
const validateNotNegativeNumber = [required(), number(), validateNotPositive];
const validateNotSmallerThanOneNumber = [required(), number(), validateNotSmallerThanOne];
const validateFreeWaitMin = [required(), number(), smallerThan60];

// const EditConfig = ({ staticContext, ...props }) => {
const EditConfig = props => {
    return (
        <Edit
            actions={<EditActions />}
            id='noid'
            resource="config"
            basePath="/config"
            redirect="false"
            title={<Title />}
            {...props}
        >

            {/* <SimpleForm redirect="list">
                
            <TextInput source="currency_code" label="Code de monnaie"/>
            

        </SimpleForm> */}
            <TabbedForm toolbar={<EditToolbar />}>
                <FormTab label="Générale">
                    {/* <TextInput source="currency_code" label="Code de monnaie"/>
                <TextInput source="currency_symbol" label="Symbole de monnaie"/> */}
                    {/* <TextInput source="unit_time" label="Symbole de monnaie"/> */}
                    {/* <NumberInput source="commission" label="Commission service (%)" validate={validateNotNegativeNumber}/> */}
                    <NumberInput source="vat_2" label="TVA Auto-entrepreneur (%)" validate={validateNotNegativeNumber} />
                    <NumberInput source="vat_6" label="TVA Flotte (%)" validate={validateNotNegativeNumber} />
                    <NumberInput source="vat_subscription" label="TVA abonnement (%)" validate={validateNotNegativeNumber} />
                    <NumberInput source="vat_commission" label="TVA commission (%)" validate={validateNotNegativeNumber} />
                    <NumberInput source="coef_base_max" label="Coefficient max.  (%)" validate={validateNotNegativeNumber} style={{ 'width': '400px' }} />
                    {/* <NumberInput source="coef_per_km_max" label="Coefficient max. au prix par km (%)" validate={validateNotNegativeNumber} style={{'width':'400px'}}/>
                <NumberInput source="coef_per_min_max" label="Coefficient max. au prix par minute (%)" validate={validateNotNegativeNumber} style={{'width':'400px'}}/> */}
                    {/* <NumberInput source="doc_validation_warning_before" label="Informé pour l'expiration de documents avant (jours)"  
                initialValue={1} style={{'width':'600px'}}
                validate={validateNotSmallerThanOneNumber}/> */}
                </FormTab>
                <FormTab label="Commande">

                    {/* <TextInput fullWidth source="manual_dispatch_postal_codes" style={{'width':'50%'}} helperText="Exemple: 13001-13999, 14000, 14007. Tandis que 13001-13999 pour les codes postaux de 13001 à 13000. 14000 et 14007 sont des valuers individuelles. Séparées par des virgules"
                    label="Zones (codes postaux) pour le dispatching manuel"/> */}
                    <NumberInput source="timeout_order_wait_for_accept" label="Délai d'acceptation - immediate (minutes)" validate={validatePositiveNumber} style={{ 'width': '600px' }} />
                    <NumberInput source="timeout_order_wait_for_accept_reservation" label="Délai d'acceptation - réservation (minutes)" validate={validatePositiveNumber} style={{ 'width': '600px' }} />
                    {/* <NumberInput source="redispatch_timeout_reservation" label="Délai d'acceptation du chauffeur partenaire - réservation (minutes)" validate={validatePositiveNumber} style={{'width':'600px'}}/> */}
                    <NumberInput source="free_wait_min_immediate" label="Immédiate - Temps d'attente gratuit (minutes)" validate={validateFreeWaitMin} style={{ 'width': '600px' }} />
                    <NumberInput source="free_wait_min_reservation" label="Réservation - Temps d'attente gratuit (minutes)" validate={validateFreeWaitMin} style={{ 'width': '600px' }} />
                    {/* <NumberInput source="over_wait_cancel_immediate" label="Immédiate - Annulation fin d'attente (%)" style={{ 'width': '600px' }} />
                    <NumberInput source="over_wait_cancel_reservation" label="Réservation -  Annulation fin d'attente (%)" style={{ 'width': '600px' }} /> */}

                    {/* <NumberInput source="wait_price" label="Prix d'attente (€/minute)" initialValue={0} validate={required()} style={{'width':'600px'}}/> */}
                    {/* <NumberInput source="cancel_free_driver" label="Temps d'annulation gratuit(s) du chauffeur" validate={required()} style={{'width':'600px'}}/>
                <NumberInput source="cancel_free_client" label="Temps d'annulation gratuit(s) du client" validate={required()} style={{'width':'600px'}}/> */}

                    {/* <NumberInput source="service_avail_center_lat" label="Latitude du centre du service" validate={[required()]} style={{'width':'25%'}}/>
                <NumberInput source="service_avail_center_lng" label="Longitude du centre du service" validate={[required()]} style={{'width':'25%'}}/>
                <NumberInput source="service_avail_radius" label="Rayon du service(km)" validate={validateNotNegativeNumber} style={{'width':'25%'}}/>
                 */}

                    {/* <Typography variant="h6" gutterBottom>Immédiate</Typography> */}
                    <NumberInput source="pick_up_radius" label="Rayon autour point de départ(km) pour recevoir la commande" validate={required()} style={{ 'width': '600px' }} />
                    <NumberInput source="search_radius" label="Rayon (m) pour la recherche des courses low cost" validate={required()} style={{ 'width': '600px' }} />
                    <NumberInput source="driver_penalty_cancel_duration" label="Duréee de la pénalité d'annulation du chauffeur (mois)" validate={validateNotNegativeNumber} style={{ 'width': '600px' }} />
                    <NumberInput source="driver_penalty_late_duration" label="Duréee de la pénalité de retard du chauffeur (mois)" validate={validateNotNegativeNumber} style={{ 'width': '600px' }} />
                    <NumberInput source="at_pick_radius" label="Rayon autour point de départ (m) pour être sur place" validate={required()} style={{ 'width': '600px' }} />
                    {/* <NumberInput source="wait_radius" label="Rayon autour point de départ(m) pour l'attente" validate={required()} style={{'width':'600px'}}/>
                <NumberInput source="dispose_radius" label="Rayon autour point de dépose(m) pour l'alerte" validate={required()} style={{'width':'600px'}}/> */}


                    {/* <Typography variant="h6" gutterBottom>Réservation à l'avance</Typography> */}
                    <NumberInput source="soonest_reservation_time" label="Heure de réservation au plus tôt (minutes)" validate={validateNotNegativeNumber} style={{ 'width': '600px' }} />
                    <NumberInput source="latest_reservation_time" label="Heure de réservation au plus tard (minutes)" validate={validateNotNegativeNumber} style={{ 'width': '600px' }} disabled />
                </FormTab>

                {/* <FormTab label="Code de réduction">
                <TextInput source="first_order_promo_code_value" label="Valeur du code de réduction pour le nouveau compte client" fullWidth validate={required()}/>
                
            </FormTab> */}
                {/* <FormTab label="Comptes">
                <TextInput source="system.mail.sender" label="Expéditeur" validate={required()}/>
                <TextInput source="system.mail.receiver" label="Destinataire" validate={required()}/>
            </FormTab> */}


                <FormTab label="Coordonnées RVB">
                    <TextInput source="company_name" label="Nom" validate={required()} fullWidth />
                    <TextInput source="company_address_line1" label="Adresse ligne 1" validate={required()} fullWidth multiline />
                    <TextInput source="company_address_line2" label="Adresse ligne 2" validate={required()} fullWidth multiline />
                    <TextInput source="company_email" label="Email" />
                    <TextInput source="company_phone" label="Téléphone" />
                    <TextInput source="incident_phone" label="Téléphone pour incidents" />
                    <TextInput source="contact_email" label="Email de contact" />
                    <TextInput source="register_email" label="Email pour l'inscription" />
                    <TextInput source="admin_email" label="Email de l'admin" />
                </FormTab>


            </TabbedForm>


        </Edit>
    );
};

export default EditConfig;
