
import React, {Link} from 'react';
import { ListButton, EditButton, ReferenceField, ReferenceArrayField, ChipField, SingleFieldList, SimpleShowLayout,TextField, Show, useTranslate, ImageField, TopToolbar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {BACKEND_FILE_URL, locationDict} from '../../util/variant'
import {Status} from '../../util/constant'
import {FormattedDateField, ValidateField} from '../../control/field/index'
import {DataLockButton, DataUnlockButton, VehicleClassButton} from '../../control/button/index'
import { ImageViewer } from '../../control/viewer';

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorPrimary: {
        color:'#F4004E',
    },
});

const styles = {
    flex: { display: 'flex'},
    images:{display: 'flex', paddingLeft:'1em', marginLeft:"3em" },
    flexColumn: { display: 'flex', flexDirection: 'column', },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    map:{ flex: 1, marginTop: '1em', marginBottom: '1em' },
    button:{ flex: 1, marginTop: '1em'},
};

// const changePassword = () => {
//     // window.location.href = '/#/change-password';
// }

const ShowActions = ({ basePath, data, resource }) => (
    // <CardActions style={cardActionStyle}>
    //     <EditButton basePath={basePath} record={data} />
    // </CardActions>
    <TopToolbar>
        <ListButton basePath={basePath}/>
        {/* {typeof data != 'undefined' &&  typeof data.is_locked != 'undefined' && data.is_locked === Status.UNLOCKED &&
            <DataLockButton basePath={basePath} record={data} resource={resource}/>
        }
        {typeof data != 'undefined' &&  typeof data.is_locked != 'undefined' && data.is_locked === Status.LOCKED &&
            <DataUnlockButton basePath={basePath} record={data} resource={resource}/>
        } */}
        {/* <EditButton basePath={basePath} record={data} />     */}
    </TopToolbar>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle.show')}: {record ? `${record.plate_number}` : ''}</span>;
    
};

const VehicleContentPanel = ({ id, record, resource }) => {
    const classes = useStyles();
    console.log("Vehicle record " + JSON.stringify(record));
    var transportInsuranceCertUrl = record.transport_insurance_cert_url;
    // var hasTransportInsurance = transportInsuranceCertUrl != null && transportInsuranceCertUrl != "";
    if(transportInsuranceCertUrl !== '' && !transportInsuranceCertUrl.startsWith("http://") && !transportInsuranceCertUrl.startsWith("https://"))
    transportInsuranceCertUrl = BACKEND_FILE_URL + transportInsuranceCertUrl;
    var greenCardCertUrl = record.green_card_url;
        if(greenCardCertUrl !== '' && !greenCardCertUrl.startsWith("http://") && !greenCardCertUrl.startsWith("https://"))
        greenCardCertUrl = BACKEND_FILE_URL + greenCardCertUrl;
    var grayCardUrl = record.gray_card_url;
    if(grayCardUrl !== '' && !grayCardUrl.startsWith("http://") && !grayCardUrl.startsWith("https://"))
        grayCardUrl = BACKEND_FILE_URL + grayCardUrl;
    
    var frontImageUrl = record.front_image_url;
    if(frontImageUrl !== '' && !frontImageUrl.startsWith("http://") && !frontImageUrl.startsWith("https://"))
        frontImageUrl = BACKEND_FILE_URL + frontImageUrl;

    var rearImageUrl = record.rear_image_url;
    if(rearImageUrl !== '' && !rearImageUrl.startsWith("http://") && !rearImageUrl.startsWith("https://"))
        rearImageUrl = BACKEND_FILE_URL + rearImageUrl;

    var luggageBoxUrl = record.luggage_box_url;
    if(luggageBoxUrl !== '' && !luggageBoxUrl.startsWith("http://") && !luggageBoxUrl.startsWith("https://"))
        luggageBoxUrl = BACKEND_FILE_URL + luggageBoxUrl;

    var wholeImageUrl = record.whole_image_url;
    if(wholeImageUrl !== '' && !wholeImageUrl.startsWith("http://") && !wholeImageUrl.startsWith("https://"))
        wholeImageUrl = BACKEND_FILE_URL + wholeImageUrl;

    var insideImageUrl = record.inside_image_url;
    if(insideImageUrl !== '' && !insideImageUrl.startsWith("http://") && !insideImageUrl.startsWith("https://"))
        insideImageUrl = BACKEND_FILE_URL + insideImageUrl;

    var files = [];
    files[0] = {file_url: greenCardCertUrl, name: 'Carte verte'};
    files[1] = {file_url: transportInsuranceCertUrl, name: "Attestation d'assurance avec plaque du véhicule"};
    files[2] = {file_url: grayCardUrl, name: 'Carte grise'};
    files[3] = {file_url: frontImageUrl, name: 'Photo du véhicule face avant plaque visible'};
    files[4] = {file_url: rearImageUrl, name: 'Photo du véhicule face arrière plaque visible'};
    files[5] = {file_url: wholeImageUrl, name: 'Photo du véhicule en largeur mode paysage'};
    files[6] = {file_url: insideImageUrl, name: 'Photo intérieure arrière du véhicule'};
    files[7] = {file_url: luggageBoxUrl, name: 'Photo du coffre'};
    
    
    
    return(
       
            <div style={styles.flexColumn}>
                {/* <span>Couleur : {record['color']}</span><br/>
                <span>Nombre de siège passager max. : {record['seats']}</span><br/>
                <span>Nombre de bagage max. : {record['luggages']}</span><br/>
                <span>Litrage du coffre : {record['luggage_volume']}</span><br/>
                
                <span>Motorisation (Cheveaux fiscaux) : {record['horse_power']}</span><br/> */}
                <div style={styles.images}>        
                    {files.map(file => {
                        return <ImageViewer files={files} fileUrl={file.file_url} title={file.name} width='300' height='300'/>
                    })}
                </div>
                {/* <VehicleDateField record={record}/> */}
                {/* <div className={classes.noColorLabel}><span>Actions: </span>
                {console.log("Vehicle is locked = " + record.is_locked)}
                {record.is_locked === Status.UNLOCKED && <VehicleLockButton record={record}/>}
                {record.is_locked === Status.LOCKED && <VehicleUnlockButton record={record}/>}
                {record.status == DataStatus.NEW && 
                <VehicleApproveButton record={record}/>}
                {record.status == DataStatus.NEW && 
                <VehicleRejectButton record={record}/>}
                </div> */}
            </div>
    );
};
// const TypeTextField = ({ source, record = {}, label}) => {
//     const locationType = locationDict[record.type];
//     return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
// }
    
// TypeTextField.defaultProps = {
//     addLabel: true,
// };

// const userId = localStorage.getItem('user_id');
// const role = localStorage.getItem('role');

const ShowVehicle = ({staticContext, ...props}) => {
    const classes = useStyles();
    return(
    <Show title={<Title />} {...props} actions={<ShowActions />}>
            
        <SimpleShowLayout>
                        <TextField source="plate_number" label="Immatriculation" />
                        {/* <ReferenceField label="Marque" source="brand_id" reference="vehicle_brand">
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Modèle" source="model_id" reference="vehicle_model">
                            <TextField source="name" />
                        </ReferenceField> */}

                        <TextField source="brand_name" label="Marque" className={classes.inlineBlock}/>
                        <TextField source="model_name" label="Modèle" className={classes.inlineBlock}/>
                        {/* <TextField source="vehicle_type_name" label="Type"/> */}
                        
                        {/* <TextField source="color" label="Couleur"/> */}
                        <div/>
                        <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" link="">
                            <TextField source="name" />
                        </ReferenceField>

                        <ReferenceField label="Gamme de véhicule" source="vehicle_class_id" reference="vehicle_class" link="" className={classes.inlineBlock}>
                            <TextField source="name" />
                        </ReferenceField>

                        <VehicleClassButton className={classes.inlineBlock}/>
                        <di/>
                        <TextField source="color" label="Couleur"/>
                        <TextField source="horse_power" label="Puissant fiscale (cv)"/>
                        <ReferenceField label="Type de carburant" source="fuel_type_id" reference="fuel_type" perPage={1000} link="">
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Type de revêtement siège" source="seat_cover_type_id" reference="seat_cover_type" perPage={1000} link="">
                            <TextField source="name" />
                        </ReferenceField> 
                        <TextField source="luggages" label="Nombre de bagages maximum"/>
                        <TextField source="seats" label="Nombre de sièges passagers"/>
                        <TextField source="luggage_volume" label="Litrage du coffre"/>
                        
                        <ReferenceArrayField label="Options" reference="option" source="services" perPage={1000} link="">
                                        <SingleFieldList>
                                            <ChipField source="name" />
                                        </SingleFieldList>
                                    </ReferenceArrayField>
                        <FormattedDateField source="circulation_date" label="Date de la 1e mise en circulation"  locales="fr-FR"/>
                        <FormattedDateField source="control_date" label="Date du contrôle technique"  locales="fr-FR"/>
                        <ReferenceField label="Chauffeur" source="driver_id" reference="driver" link="" perPage={10000}>
                            <TextField source="email" />
                        </ReferenceField>
                        <VehicleContentPanel/>
            {/* <TextField source="name" label = "Nom"/>
            <ValidateField source="is_locked" label="Verrouillée"/> */}
        </SimpleShowLayout>
    </Show>);
};


export default ShowVehicle;