import React from 'react';

const Logo = props => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
  x="0px" y="0px" width="59px" 
  height="53px" viewBox="0 0 59 53" enable-background="new 0 0 59 53" 
  >  <image id="image0" width="59" height="53" x="0" y="0"
  href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADsAAAA1CAQAAABVJltIAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZ
cwAALEsAACxLAaU9lqkAAAAHdElNRQfmCRQQJDL7u+PQAAADsnpUWHRSYXcgcHJvZmlsZSB0eXBl
IHhtcAAASImtVluSm0AM/NcpcgSQZiTmONjAX6rymeOnW+M1tmGzzlZMGewZPVqtxyC/f/6SH/iM
dahiV9tiisFHN794jaKDq1cPb77aorpul8tlU8V688KVGlbLYkNZYigG2cmblCnmgGK1mMtai+MJ
g2ZQUrXNVh3sGpPNMTkUfaEzH3Xgf7/6GsY9oQegKb4Rh8194y6eSHYzWLtQo9w1dKhTWeogSnBb
5JK5rjboAjwDLpiwsIa10dwKLsfuFatq+dUNz4r7aCq6pNiMpYL7aKHDy6W38BQoIFm1lOIvoank
JsObouAabEY4W+RH14CUrok40nPjlUgUd8V96Q6AKCyQHzISE8KCB+4/owAEpAqJUG/JVANDkPjY
91FA2BYglqg6sY+5IMFHvOls3VOE3yb4uSCUCXhI3ph0b68moWInBAyAoH5l8NKjpyDuFYqVqWeo
8NoNj0fDqKcJkQARAuavQd4DfsN9UN9jkHNeyoIW+BcXkzxzA4PLSSAWpdTwnpJz43Jm/dk4OzSQ
dMhsVbMMtiQZ7UrznVFhzXT/9FFYKRWGyINWOtn8euiuI1cgW9MzjCmaL4dJL88U0vaJm1pKf9IJ
+056maPkG+5YqOXWdfzMRt1Ltii7cXgyuzjLprlHK0We7Z6YHfGPRjBi8PXPzEq5TR7vUMdXyO+a
lt12tsHsnr2+0Fwmu/E3Bm/NglzRB8RxUtkzBxsqBuq3qDsaO1eAfOOMq+z8DW4b28oWVDbqRFFh
UIMIkGNacO4gfQwme+3ORVY8TLEMk5A1q4lk03sXQkm2PCdmeAgIVT5hMmAQdYf/ZItw2OILdlAK
xhnm1gS3PDGsp51jyg8IWIoW97lABK+HRm8R60jOGIHQxOHuCnSlY8JaveFUHgnANAqELOEhrVym
FyriWyGkB3TMaevHJ2SvH2jlFe6dr9JRGn02ZIBmg4cVHBh+RefLOwx0P84wAnaKIV8kFWpkbALC
6X4MPKLCEZ1ZA7pofV/upxV8xvh8dqBCGgLbp9HMpv44Q5LuexsLHiV5eBQ/qZOzMnkMW/4W99dh
7wCECCKQ7NrBftKoX40Wkz0gjlDMlgKzIyMv2arM6P+akNs7KZDHHEB1zGH+Dfrl+2363KXy0KYP
CN6g/2VOypH/79EvR/7fOqAe+aMmem1NL/39aNtzpNtZ8+Q5fNpz8tF0u+L529nBfL5J7ubl9f25
b528xIObjpvHCN+/5Q9qjr1P34AUdAAABXBJREFUWMPt2G1slWcZB/DfOXDaQsvbhM3xPjYYY0Ir
mBgcZrwt2weiMBPnYnTBSYwFZ3RGnS7ZoiZz08wYlUxxTmUq6gYLIX4A1EGALYJsU4plhZQVoS2l
lFG6vp/jhz69z3N6ztlW9Ju9ng/Pff2v/7n+z/3yXPf9nFGuxuYpU+4Ry13Q7P2ahpsgcRWiy63T
pVOJdrNd8ZZaO7w1nBTJYYsmbdSm1nqt6nzINK+43ezhJRk1bNmMk97jkj77XdKm1hgn/PUqRm1Y
Vo5l7sV2GaMtUm2MCqOHk+Tdz22lqW7Rp03CRttwxjlL/ds3POeMlHFanHZc6/Bk77DCdkfyODdZ
ZrZa5RJ2a9atzGV/9ElUOug2rxmtwmpTnLIILzmYl6XCA7o8NbD0snN7j1u9odoLegNWaq07zVJi
olbX2+d1/ZhprcecxAV3aPKatC5U6kWHUuvcqF1bTPYzxmtzp8N6473dYgOW+HvkV3mf6dr1yJgs
7WWvuhzFkuar1wkWaHIxwsda7MPKHNOrV5UW9fZEsRu0uOIB+7062Nup2o3xBc2OmGKlj1topQ0O
aTJdv+f9yRu6wyNmtOiL2i2RPPRqcMDrFpvjnFEe126WtWboUWecauwyRceA7CrVvu+KSh+1Sakq
73XeZF2abXbYm8NZpdodsc9Cq01wyDQfc8a9qkzS4xkPK1czOMj3W+JFjb6kygFNytTb65/Dksu3
OT5iugk6zdDnh661xBlP683O7WLLdEtIS3vZsf9SMG4z3W6sHl2uccgr/8PMIzZi/5+WwLghWE+s
DGatIme36gzFMaUsoO3RfZSx+iUllUjI6NYV22BAnctDrvOO+7X5OazrdedwzrkvivxCe0C3uA6s
1eWiS9p16tLpskY1trk1mzBT9Ho0JnttgfiA/SYHexKsKZrzO4MJb7QnBtfbZrueyNshFYQrHY/x
TvtqhI/zRECPWBZN3X3qY+ydtuqK2r8fnJTxIdyqBNwTkJ/Eejw/lui6GD43wp7PmZZNgfsUeDT4
Dw8QbgrAv8KP2iKkI7ZgOBiYd8fQv0TYpBzZrwRuNVgRGxNJYme+7Km5MbqPtTiW6sehFebIZCvA
1pwjTDzrGDAz+BOHHs8T4T4tYOWx+M6Q+hZzo9Zgv7cpZgOvzmdjozNEdnB1fsL4qJXO2R87PBeb
O7F0jfYWlU1K+Wa02GgafEOyS6UW3Kw1IL8bkmJBiLRIYULk/TRP7OuBeV5jaG/NFp2s7GW/9aJ0
8E+6Ji/d3hBdh29F7Q+8jWz8ir0Z8wsSMnaHoY5bthTswDEZGX8rwPta4DXZ5yUnIq/B0uKynaqK
zlVLxOlTHU3HgwVYD4Vcg9HNAflgrmyNNTqjlD8qKvvQkEdMm1CAlS0PX46QGQGpS8quX9J2+TwY
ZZPvFpF9TE+Of+gdztEl0b0/ILOSxJJU4JdqwuzcXCTRszneEwU5nXnIgtDqJ784VgW/2Gl5bmyI
24pwngyM9SChLiAXuc2fg9vhcUn8PCA/K/K5fHhIoc+18T7lUmDssdEjamOP+r38/faoJM7GSsiC
AolXhXhlgej9RV7Jgesf8Ks8eILcXXhzwf6+EBWYQrZITRHJE74tMVD8V5tncD2n1NsVtT9tol4l
9hf5bvmcOXYW+G4fsA1KpXVLK1VqtD5NThX4r2DERmzERuzt7N38sZtSKiUhPQRfabqGHCRhjDKj
yeO+gz3ji5ImudsP7NcWO84NVOADllulz1nrZWTc5aJuS61xKq/+vumoLTa4AQ/6Q1xm6LZ2WCPS
OtS5osFkU6WkJGT0a3VUuz67pTRo06XREXTo87SF5hkrhX69zmp22gXn9aEhfA+D/wBN8+X1Xgrf
gAAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMi0wOS0yMFQxNjozNjo1MCswMDowMMlFhOEAAAAldEVY
dGRhdGU6bW9kaWZ5ADIwMjItMDktMjBUMTY6MzY6NTArMDA6MDC4GDxdAAAAKHRFWHRkYXRlOnRp
bWVzdGFtcAAyMDIyLTA5LTIwVDE2OjM2OjUwKzAwOjAw7w0dggAAAABJRU5ErkJggg==" />
</svg>
);

export default Logo;
