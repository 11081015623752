import React, {Fragment} from 'react';
import { ReferenceField, List, Datagrid, TextField, DateField, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import {COMMISSION_TYPE} from '../../util/constant'
import {OrderTypeField, OrderTypeFilter, ActivityTypeField} from '../../control/field/index'

const BulkActionButtons = props => (
    <Fragment>
        <BatchDeleteButton label="Supprimer" {...props} />
    </Fragment>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.commission.list')}</span>;
};

// const TypeTextField = ({ source, record = {}, label}) => {
//     const locationType = locationDict[record.type];
//     return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
// }
    
// TypeTextField.defaultProps = {
//     addLabel: true,
// };

const ListFilter = ({ permissions, ...props })  => (
    <Filter {...props}>
        {/* <TextInput label="Name" source="name" alwaysOn resettable/>
        <TextInput label="Address" source="address" alwaysOn resettable/>
        <SelectInput label="Type" source="type" alwaysOn choices={locationTypes}
            allowEmpty emptyText="Tous les types" emptyValue='0' initialValue='0'
        /> */}
        
    </Filter>
);

const ListPrice = ({permissions, ...props}) => (
    <List title={<Title/>} {...props} 
        filters = {<ListFilter permissions={permissions} />} 
        bulkActionButtons={<BulkActionButtons />} exporter={false} resource="commission"  filter={{type: COMMISSION_TYPE}}>
        <Datagrid rowClick="edit">
            <TextField source="from_count" label="Du nombre de chauffeurs"/>
            <TextField source="to_count" label = "Au nombre de chauffeurs"/>
            <TextField source="price" label="% de commission/chauffeur"/>
            
        </Datagrid>
    </List>
);

export default ListPrice;

